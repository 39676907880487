import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";

import { Btn, Image } from "../../../AbstractElement";
import { dynamicImage } from "../../../Helper";
import useApiState from "../../../Hooks/useApiState";
import { useAdminAuthContext } from "../../../Context/Auth/AdminAuthContext";

const AdminLoginForm = () => {
  const [show, setShow] = useState(false);
  const toggleShow = () => setShow(!show);
  const { handleAdminLogin, AdminLoginSchema:LoginSchema } = useAdminAuthContext();
  const { register, handleSubmit, formState: { errors }} = useForm({ resolver: yupResolver(LoginSchema)});
  const navigate = useNavigate();
  const { loading, setLoading } = useApiState();

  const onSubmit = (data) => handleAdminLogin({ data, navigate, setLoading });

  return (
    <Form className="auth-login_form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormGroup className="position-relative">
        <Image src={dynamicImage("svg/sms.svg")} className="form-icon" />
        <input {...register("email")} className={`form-control ${errors.email ? "invalid-field" : ""}`} placeholder="Enter Email Address" type="email" />
        {errors.email && <span className="required-note">{errors.email.message}</span>}
      </FormGroup>
      <FormGroup className="position-relative">
        <Image src={dynamicImage("svg/lock.svg")} className="form-icon" />
        <input {...register("password")} className={`form-control pr-45 ${errors.password ? "invalid-field" : ""}`} placeholder="Password" type={show ? "text" : "password"} />
        {errors.password && <span className="required-note">{errors.password.message}</span>}
        <div className="password-wrap" onClick={toggleShow}>
          <Image src={dynamicImage(`svg/${show ? "eye" : "eye-slash"}.svg`)} />
        </div>
      </FormGroup>
      <Btn block className="btn-theme" type="submit" disabled={loading} loading={loading}>
        Sign in
      </Btn>
    </Form>
  );
};

export default AdminLoginForm;
