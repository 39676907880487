import React, { useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import { Image } from "../../AbstractElement";
import { dynamicImage } from "../../Helper";
import { useSetUrlParams } from "../../Api/UrlParams";

const CreatorSelect = ({ options, selectedItem, setSelectedItem, handlePlatformChange }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const setParam = useSetUrlParams();

  const handleSelect = (item) => {

    // If the platform is disabled, don't allow selection
    if (item.disabled) return;
    setSelectedItem(item);
     // Update the URL parameters
     const params = {
      platform: item?.title,
     };

     const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => {
        return value !== null
      })
    );
     
     setParam(filteredParams);
    
    handlePlatformChange && handlePlatformChange();
  };

  return (
    <div className="short-bay-box">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret>{selectedItem ? <Image src={dynamicImage(selectedItem.image)} className="img-short" alt={selectedItem.title} /> : "Platform"}</DropdownToggle>
        <DropdownMenu>
          {options.map((item, index) => (
            <DropdownItem key={index} onClick={() => handleSelect(item)} className={`text-capitalize ${item.disabled ? "disabled" : ""}`}  disabled={item.disabled}>
              <Image src={dynamicImage(item.image)} className="img-short" alt={item.title} />
              {item.title}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default CreatorSelect;
