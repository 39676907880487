import { Toaster } from "react-hot-toast";

// dynamic image
const images = require.context(`/public/assets/images`, true);

// dynamic image
const svgImage = require.context(`/public/assets/images/svg`, true);

export const dynamicImage = (image) => {
  return images(`./${image}`);
};


export const dynamicSvg = (image) => {
  return svgImage(`./${image}`);
};


// CustomToaster
export const CustomToaster = () => (
  <Toaster
    toastOptions={{
      duration: 3000,
      position: "top-right",
      style: {
        fontWeight: 700,
        minWidth: 200,
      },
      success: {
        style: {
          background: "rgb(229, 245, 227)",
          color: "rgba(var(--success), 1)",
        },
      },
      error: {
        style: {
          background: "rgb(255, 238, 237)",
          color: "rgba(var(--error), 1)",
        },
      },
    }}
  />
);

// Format Instagram Number with M/K
export const formatInstagramNumber = (num) => {
  if (num >= 1000000) {
    const formattedNum = (num / 1000000)?.toFixed(2);
    return formattedNum.endsWith(".00") ? `${parseInt(formattedNum)} M` : `${formattedNum} M`;
  } else if (num >= 1000) {
    const formattedNum = (num / 1000)?.toFixed(2);
    return formattedNum.endsWith(".00") ? `${parseInt(formattedNum)} K` : `${formattedNum} K`;
  }
  return num;
};

// Format Instagram Number with Million/Thousand
export const formatInstagramNumberFull = (num) => {
  if (num >= 1000000) {
    const formattedNum = (num / 1000000)?.toFixed(2);
    return formattedNum.endsWith(".00") ? `${parseInt(formattedNum)} Million` : `${formattedNum} Million`;
  } else if (num >= 1000) {
    const formattedNum = (num / 1000)?.toFixed(2);
    return formattedNum.endsWith(".00") ? `${parseInt(formattedNum)} Thousand` : `${formattedNum} Thousand`;
  }
  return num;
};

export const parseInstagramNumber = (str) => {
  if (typeof str !== "string") return NaN;

  // Remove any non-numeric characters and convert to number
  const numberPart = parseFloat(str.replace(/[^0-9.]/g, ""));

  // Determine the multiplier based on suffix
  if (str.includes("M")) {
    return numberPart * 1000000;
  } else if (str.includes("K")) {
    return numberPart * 1000;
  }

  return numberPart;
};

// Format Percentage
export const formatPercentage = (value) => {
  if (value === 0 || value === null) {
    return "0 %";
  }
  if (value === 100) {
    return "100 %";
  }
  return `${parseFloat(value?.toFixed(2))} %`;
};

// ScrollToTop
export const scrollToTop = (scroll = 0) => {
  window.scrollTo({
    top: scroll,
    behavior: "smooth",
  });
};

// Delay
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Helper function to parse range values from a string
export const parseRange = (param) => {
  if (typeof param === "string" && param.includes("-")) {
    const [min, max] = param.split("-").map(Number);
    return {
      min: isNaN(min) ? null : min,
      max: isNaN(max) ? null : max,
    };
  }
  return { min: null, max: null };
};

// 01 Jan, 1970
export const formatDate = (dateString) => {
  const date = new Date(dateString);

  // Get day, month, and year
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  // Array of month names
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const month = monthNames[date.getUTCMonth()];

  // Return formatted string
  return `${day.toString().padStart(2, "0")} ${month}, ${year}`;
};

  //  Jan,01 1970
  export const formatDateNew = (dateString) => {
    // Check if the input is null or undefined
    if (!dateString) {
      return null;
    }

    const date = new Date(dateString);

    // Check if the date is invalid
    if (isNaN(date.getTime())) {
      return null; // Return null for invalid date
    }

    // Get day, month, and year
    const day = date.getUTCDate();
    const year = date.getUTCFullYear();

    // Array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getUTCMonth()];

    // Return formatted string
    return `${month} ${day.toString().padStart(2, "0")}, ${year}`;
  };

  export const formatDateTimeNew = (dateString) => {
    // Check if the input is null or undefined
    if (!dateString) {
      return null;
    }
  
    const date = new Date(dateString);
  
    // Check if the date is invalid
    if (isNaN(date.getTime())) {
      return null; // Return null for invalid date
    }
  
    // Format date to IST (Asia/Kolkata timezone)
    const options = {
      weekday: "short", // "Mon", "Tue" (optional)
      year: "numeric",
      month: "short", // "Jan", "Feb", etc.
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false, // 24-hour format
      timeZone: "Asia/Kolkata",
      timeZoneName: "short", // Add the timezone abbreviation (e.g., IST)
    };
  
    const formattedDate = date.toLocaleString("en-IN", options); // Formatting in IST
  
    // Optionally, you can replace timezone abbreviation if needed
    return `${formattedDate}`; // Ensuring "IST" is displayed at the end
  };


  export const formatAndSortDates = (dateArray) => {
    // Convert dates to IST, format them, and retain the original Date objects for sorting
    const formattedDates = dateArray.map((utcString) => {
      const utcDate = new Date(utcString);
  
      // Check if the date is valid
      if (isNaN(utcDate.getTime())) {
        console.error(`Invalid date: ${utcString}`);
        return null;
      }
  
      // Convert to IST
      const istOffset = 5.5 * 60 * 60 * 1000; // IST is UTC + 5:30
      const istDate = new Date(utcDate.getTime() + istOffset);
  
      // Format IST date to "DD-MM-YYYY HH:mm:ss"
      const day = istDate.getDate().toString().padStart(2, "0");
      const month = (istDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const year = istDate.getFullYear();
      const hours = istDate.getHours().toString().padStart(2, "0");
      const minutes = istDate.getMinutes().toString().padStart(2, "0");
      const seconds = istDate.getSeconds().toString().padStart(2, "0");
  
      return {
        originalDate: istDate, // Retain the IST date object for sorting
        formattedDate: `${day}-${month}-${year} ${hours}:${minutes}:${seconds}` // Formatted date
      };
    }).filter(Boolean); // Remove nulls (invalid dates)
  
    // Sort by original IST date objects in ascending order
    const sortedDates = formattedDates.sort((a, b) => a.originalDate - b.originalDate);
  
    // Extract the formatted dates
    return sortedDates.map((item) => item.formattedDate);
  };


// // Function to filter, sort dates in descending order, and format them
// export const sortDatesDescendingWithTimestamp = (datesArray) => {
//   if (!Array.isArray(datesArray)) {
//     throw new Error("Input must be an array of date strings.");
//   }

//   return datesArray
//     .map(dateString => {
//       const date = new Date(dateString);
//       return !isNaN(date.getTime()) ? date : null; // Convert to Date or return null for invalid dates
//     })
//     .filter(date => date !== null) // Remove invalid dates
//     .sort((a, b) => b - a) // Sort dates in descending order
//     .map(date => formatDateWithTimestamp(date.toISOString())); // Format sorted dates with timestamp
// };


  
  
  // Function to filter and sort dates in descending order
  export const sortDatesDescending = (datesArray) => {
    if (!Array.isArray(datesArray)) {
      throw new Error("Input must be an array of date strings.");
    }
  
    return datesArray
      .map(dateString => {
        const date = new Date(dateString);
        return !isNaN(date.getTime()) ? date : null; // Convert to Date or return null for invalid dates
      })
      .filter(date => date !== null) // Remove invalid dates
      .sort((a, b) => b - a) // Sort dates in descending order
      .map(date => formatDateNew(date.toISOString())); // Format sorted dates
  };
  


// export const Data = ({ security }) => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   // Extract authentication paths from AuthRoutes
//   const authPaths = AuthRoutes.map((route) => route.path);
//   // Combine both ProtectedRoutes and ProtectedProfileRoutes
//   const securePaths = [...(ProtectedRoutes || []), ...(ProtectedProfileRoutes || []), ...(AdminRoutes || [])].map((route) => route.path);
//   useEffect(() => {
//     const isAuthPath = authPaths.includes(location.pathname);
//     const isSecurePath = securePaths.includes(location.pathname);
//     if (security) {
//       if (!isAuthPath && !isSecurePath) {
//         toast.error("Page Not Found !!!");
//         navigate(ROUTES.Home);
//       } else if (isAuthPath) {
//         toast.success("User Login Already");
//         navigate(ROUTES.Home);
//       } else if (isSecurePath) {
//         toast.error("Access Denied Login First");
//         navigate(ROUTES.Auth.Login);
//       }
//     }
//   }, [authPaths, location.pathname, navigate, securePaths, security]);
//   return null;
// }

export const checkActiveRang = (rang) => {
  // Check if both "from" and "to" are null, return false (inactive)
  if (rang.from === null && rang.to === null) {
    return false;
  }

  // If either "from" or "to" is not null, return true (active)
  return rang.from !== null || rang.to !== null;
};
