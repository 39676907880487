import {
  AlternateEmail,
  ArrowOutward,
  Clear,
  Instagram,
  YouTube,
} from "@mui/icons-material";
import { OutlinedInput } from "@mui/material";
import React, { useCallback, useEffect, useMemo } from "react";
import { Col, Form, Row } from "reactstrap";

import { Btn, Image } from "../../../AbstractElement";
import { API } from "../../../Api/ApiList";
import { removeAllFilters } from "../../../Api/UrlParams";
import { useGetApi } from "../../../Api/useGetApi";
import { useCreatorContext } from "../../../Context/Creator/CreatorContext";
import { useCreatorFavoriteContext } from "../../../Context/Creator/CreatorFavoriteContext";
import { useCreatorProfileContext } from "../../../Context/Creator/CreatorProfileContext";
import { platformOption } from "../../../Data/FindCreatorData";
import { ROUTES } from "../../../Routes/Routes";
import {
  dynamicImage,
  formatInstagramNumber,
  formatPercentage,
} from "../../../Helper";
import useApiState from "../../../Hooks/useApiState";
import { useAutoFocus } from "../../../Hooks/useAutoFocus";
import CreatorSelect from "../../Common/CreatorSelect";
import { createSortHeader, TableCommon } from "../../Common/Table";
import { DefaultContent } from "../../Common/Table/DefaultContent";
import { useNavigate } from "react-router-dom";

const SearchByProfile = () => {
  const navigate = useNavigate();
  const {
    handleUrl,
    selectTab,
    setPlatFormSelectedItem,
    platFormSelectedItem,
    removeUrlParams,
  } = useCreatorContext();
  const { creatorProfileState, updateState, onChangeSearchQuery, clearSearch } =
    useCreatorProfileContext();
  const {
    handleSelectAllRow,
    handleTableCheckboxChange,
    isAllProfileChecked,
    selectedProfileRows,
    setSelectedProfileRows,
    setIsAllProfileChecked,
  } = useCreatorFavoriteContext();
  const { searchQuery, creatorProfileData, isSubmitted } = creatorProfileState;
  const {
    page,
    setPage,
    total,
    setTotal,
    limit,
    totalPages,
    setTotalPages,
    sortOrder,
    sortColumn,
    handleSort,
    handleLimitChange,
    params,
    setParams,
    setSortOrder,
    setDefaultSort,
  } = useApiState();
  const inputRef = useAutoFocus(selectTab === 2);

  useEffect(() => {
    if (selectTab === 1) {
      setDefaultSort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectTab]);

  const { data, isLoading, refetch } = useGetApi({
    url: API.creator.findCreatorApi,
    params: {
      ...params,
      platform:  platFormSelectedItem?.title  || null,
      platform_username: searchQuery,
      page,
      limit,
      sortOrder,
      sortColumn: sortColumn || null,
    },
    options: { enabled: false, refetchOnWindowFocus: false },
  });

  useEffect(() => {
    if (data) {
      updateState({ creatorProfileData: data.data });
      setTotalPages(data.totalPages);
      setTotal(data.total);
    }
  }, [data, setTotal, setTotalPages, updateState, refetch]);

  const submitHandler = (e) => {
    e.preventDefault();
    updateState({ isSubmitted: true });
    setParams((prevParams) => ({
      ...prevParams,
      platform_username: searchQuery,
    }));
    setPage(1);
    refetch();
  };

  useEffect(() => {
    if (isSubmitted) {
      refetch();
    }
  }, [page, limit, sortOrder, sortColumn, refetch, isSubmitted]);

  const followerHead = platFormSelectedItem?.title === "instagram";

  // Function to handle select all checkboxes
  const handleSelectAll = (e) =>
    handleSelectAllRow({ e, tableId: "profile", data: creatorProfileData });
  const handleCheckboxChange = useCallback(
    (id) => {
      handleTableCheckboxChange({
        id,
        tableId: "profile",
        data: creatorProfileData,
      });
    },
    [creatorProfileData, handleTableCheckboxChange]
  );

  const creatorProfileTableHead = [
    {
      class: "tab-check",
      render: () => (
        <input
          type="checkbox"
          className="check-box"
          checked={isAllProfileChecked}
          onChange={handleSelectAll}
        />
      ),
    },
    createSortHeader(
      "text-start",
      "Creator",
      true,
      "id",
      sortColumn,
      sortOrder,
      handleSort
    ),
    createSortHeader(
      "",
      followerHead ? "Followers" : "Subscribers",
      true,
      "follower_count",
      sortColumn,
      sortOrder,
      handleSort
    ),
    createSortHeader(
      "",
      "Engagement Rate",
      true,
      "avg_engagement",
      sortColumn,
      sortOrder,
      handleSort
    ),
    // followerHead &&
    // createSortHeader(
    //   "",
    //   "Is Verified",
    //   true,
    //   "is_verified",
    //   sortColumn,
    //   sortOrder,
    //   handleSort
    // ),
    createSortHeader(
      "",
      "Avg views",
      true,
      "avg_view_count",
      sortColumn,
      sortOrder,
      handleSort,
      "Filter creators by average views on top posts."
    ),
    createSortHeader(
      "",
      "Avg likes",
      true,
      "avg_like_count",
      sortColumn,
      sortOrder,
      handleSort,
      "Filter creators by average likes on top posts."
    ),
    createSortHeader(
      "",
      "Avg comments",
      true,
      "avg_comment_count",
      sortColumn,
      sortOrder,
      handleSort,
      "Filter creators by average comments on top posts."
    ),


  ].filter(Boolean); // Filter out false or undefined entries

  const creatorProfileTableRow = useMemo(() => {
    const createRow = (item, selectedProfileRows, handleCheckboxChange) =>
      [
        {
          class: "",
          render: () => (
            <input
              type="checkbox"
              className="check-box"
              checked={selectedProfileRows.some(
                (selected) => selected.id === item.id
              )}
              onChange={() => handleCheckboxChange(item.id)}
            />
          ),
        },
        {
          class: "",
          render: () => (
            <div className="creator-wrap">
              <div
                className="user-img"
                onClick={() => handleCheckboxChange(item.id)}
              >
                <Image
                  className="img-fluid"
                  src={item.image_url || dynamicImage("svg/dummy-user.svg")}
                  alt="User"
                  onError={(e) =>
                    (e.target.src = dynamicImage("svg/dummy-user.svg"))
                  }
                />
              </div>
              <div className="creator-detail">
                <div className="name mb-1" onClick={()=> navigate(ROUTES.Pages.CreatorProfiles.replace(':id', item?.id))}>
                  <h5>{item.full_name ? item.full_name : "Unknown"}</h5>
                  {item.is_verified && (
                    <Image
                      className="img-fluid"
                      src={dynamicImage("svg/verify.svg")}
                    />
                  )}
                </div>
                <h6>
                  {item.platform === "instagram" ? (
                    <Instagram fontSize="small" />
                  ) : (
                    <YouTube fontSize="small" />
                  )}
                  <span onClick={() => handleUrl(item)}>
                    @{item.platform_username}
                  </span>
                  <ArrowOutward fontSize="10px" />
                </h6>
              </div>
            </div>
          ),
        },
        {
          class: "",
          render: () =>
            item.follower_count !== null
              ? formatInstagramNumber(item.follower_count)
              : "NA",
        },
        {
          class: "",
          render: () =>
            item.avg_engagement !== -1
              ? formatPercentage(item.avg_engagement)
              : "-",
        },
        // followerHead && {
        //   class: "text-capitalize",
        //   render: () =>
        //     item.is_verified === true
        //       ? item.is_verified.toString()
        //       : item.is_verified === false
        //         ? item.is_verified.toString()
        //         : "-",
        // },
        {
          class: "",
          render: () =>
            item.avg_view_count !== null
              ? formatInstagramNumber(item.avg_view_count)
              : "-",
        },
        {
          class: "",
          render: () =>
            item.avg_like_count !== null
              ? formatInstagramNumber(item.avg_like_count)
              : "-",
        },
        {
          class: "",
          render: () =>
            item.avg_comment_count !== null
              ? formatInstagramNumber(item.avg_comment_count)
              : "-",
        },
        
      ].filter(Boolean); // Filter out false or undefined entries

    return creatorProfileData.map((item) =>
      createRow(item, selectedProfileRows, handleCheckboxChange)
    );
  }, [
    creatorProfileData,
    followerHead,
    handleCheckboxChange,
    handleUrl,
    selectedProfileRows,
  ]);

  const handlePlatformChange = () => {
    setDefaultSort();
    removeAllFilters("platform");
    removeUrlParams();
  };

  return (
    <Row>
      <Col sm="12">
        <div className="top-filter-panel mb-space w-100">
          <Form
            className="form-search-box w-100"
            noValidate
            onSubmit={submitHandler}
          >
            <CreatorSelect
              options={platformOption}
              selectedItem={platFormSelectedItem}
              setSelectedItem={setPlatFormSelectedItem}
              handlePlatformChange={handlePlatformChange}
            />
            <OutlinedInput
              inputRef={inputRef}
              startAdornment={<AlternateEmail className="info-icon" />}
              endAdornment={
                searchQuery.length > 0 && (
                  <Clear
                    className="cursor-pointer"
                    onClick={() => {
                      clearSearch();
                      setDefaultSort();
                      setSelectedProfileRows([]);
                      setIsAllProfileChecked(false);
                    }}
                  />
                )
              }
              className="w-100 me-0 profile-search"
              placeholder="Search using handle ..."
              value={searchQuery}
              onChange={(e) => {
                onChangeSearchQuery(e.target.value);
                e.target.value.trim() === "" && setSelectedProfileRows([]);
                setIsAllProfileChecked(false);
              }}
            />
            <Btn
              className="btn-theme search-btn"
              type="submit"
              disabled={!searchQuery.trim()}
            >
              <Image
                src={dynamicImage("svg/search.svg")}
                className="search-icon"
                alt="Settings Icon"
              />
              Search
            </Btn>
          </Form>
        </div>

        {!isSubmitted ? (
          <DefaultContent
            image="no-filter"
            title="Search for better results."
            subTitle="Try searching with different terms to find better results."
          />
        ) : (
          <TableCommon
            isLoading={isLoading}
            mainClass="creators-table-wrap"
            tabClass="align-middle profile-table creators-table"
            head={creatorProfileTableHead}
            rows={creatorProfileTableRow}
            pagination={{
              limit,
              handleLimitChange,
              totalPages,
              currentPage: page,
              setCurrentPage: setPage,
            }}
            onCheckboxChange={handleCheckboxChange}
            tableCheck={{
              tableId: "profile",
              selectedRows: selectedProfileRows,
              handleSort,
              setSortOrder,
              total,
              params,
            }}
          />
        )}
      </Col>
    </Row>
  );
};

export default SearchByProfile;
