import React from "react";
import { Col } from "reactstrap";
import { Btn, Image } from "../../AbstractElement";
import { dynamicImage, formatDateNew } from "../../Helper";
import { useCreatorContext } from "../../Context/Creator/CreatorContext";
import {  PlaylistAdd } from "@mui/icons-material";
import { useCreatorFavoriteContext } from "../../Context/Creator/CreatorFavoriteContext";
import { TableRowAddModal } from "../FindCreator/Modals/TableRowAddModal";

export const CreatorDetails = ({ user }) => {
    const { handleUrl } = useCreatorContext();
    const { tableRowAddModal, toggleTableRowAddModal } = useCreatorFavoriteContext();   
    return <>
        <Col lg={12}>
            <div className="user-img">
                <div className="profile-img">
                    <Image className="icon" src={user?.data?.influencer?.image_url || dynamicImage("svg/profile-user.svg")} alt="User" onError={(e) => { e.target.src = dynamicImage("svg/dummy-user.svg"); }} />
                </div>
                <div className="user-details">
                    <div className="w-100">
                        <h4>{user?.data?.influencer?.full_name}{user?.data?.influencer.platform === "instagram" && <Image className="img-fluid ms-2" src={dynamicImage("svg/verify.svg")} />}
                        </h4>
                        <div className="customer-badge">
                            <div className="custom-badge-url mb-3">
                                {user?.data?.influencer.platform === "instagram" ?
                                    <h6 className="mb-0" onClick={() => handleUrl(user?.data?.influencer)}><Image src={dynamicImage("svg/insta.svg")} /><span>@{user?.data?.influencer?.platform_username}</span>
                                    </h6> :
                                    <h6 className="mb-0" onClick={() => handleUrl(user?.data?.influencer)}><Image src={dynamicImage("svg/youtube.svg")} /><span>@{user?.data?.influencer?.platform_username}</span>
                                    </h6>}
                            </div>
                            {user?.data?.influencer?.category_name &&
                                <div className="custom-badge-url mb-3">
                                    <h6 className="mb-0" onClick={() => handleUrl(user?.data?.influencer)}>@{user?.data?.influencer?.category_name}
                                    </h6>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-column justify-content-between">
                        <Btn onClick={toggleTableRowAddModal} className="more-filter-btn btn-pill-light">
                            <PlaylistAdd /> Add to list
                        </Btn>
                        {/* <Btn className="btn-pill-light add-creator-btn" onClick={toggleCreatorListModal}><FormatListBulleted />Add to Favorites</Btn> */}
                        
                            <div className="custom-analytics-selected">
                                <label htmlFor="dateRangeSelect">Last updated</label>
                                <h4>{formatDateNew(user?.data?.influencer?.updated_at) || 'October 10, 2024'}</h4>
                            </div>
                        
                    </div>
                </div>
            </div>
        </Col>
        {tableRowAddModal && <TableRowAddModal singleCreator={true} />}
    </>
        ;
};
