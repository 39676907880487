export const VideoCategoryData = [
  {
    "kind": "youtube#videoCategoryListResponse",
    "etag": "QteLrrS_X7rM7rlcU_e7qa0embQ",
    "items": [
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_1",
        "id": "1",
        "snippet": {
          "title": "Autos & Vehicles",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_2",
        "id": "2",
        "snippet": {
          "title": "Comedy",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_3",
        "id": "3",
        "snippet": {
          "title": "Education",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_4",
        "id": "4",
        "snippet": {
          "title": "Entertainment",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_5",
        "id": "5",
        "snippet": {
          "title": "Film & Animation",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_6",
        "id": "6",
        "snippet": {
          "title": "Gaming",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_7",
        "id": "7",
        "snippet": {
          "title": "Howto & Style",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_8",
        "id": "8",
        "snippet": {
          "title": "Music",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_9",
        "id": "9",
        "snippet": {
          "title": "News & Politics",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_10",
        "id": "10",
        "snippet": {
          "title": "Nonprofits & Activism",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_11",
        "id": "11",
        "snippet": {
          "title": "People & Blogs",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_12",
        "id": "12",
        "snippet": {
          "title": "Pets & Animals",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_13",
        "id": "13",
        "snippet": {
          "title": "Science & Technology",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_14",
        "id": "14",
        "snippet": {
          "title": "Sports",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      },
      {
        "kind": "youtube#videoCategory",
        "etag": "etag_15",
        "id": "15",
        "snippet": {
          "title": "Travel & Events",
          "assignable": true,
          "channelId": "UCBR8-60-B28hp2BmDPdntcQ"
        }
      }
    ]
  }
  
];

export const videoLanguagesOptions = [
  { name: "Afrikaans", code: "af" },
  { name: "Albanian", code: "sq" },
  { name: "Amharic", code: "am" },
  { name: "Arabic (Saudi Arabia)", code: "ar-SA" },
  { name: "Arabic (Egypt)", code: "ar-EG" },
  { name: "Armenian", code: "hy" },
  { name: "Azerbaijani", code: "az" },
  { name: "Bengali (Bangladesh)", code: "bn-BD" },
  { name: "Bengali (India)", code: "bn-IN" },
  { name: "Bulgarian", code: "bg" },
  { name: "Chinese (Simplified, China)", code: "zh-CN" },
  { name: "Chinese (Traditional, Taiwan)", code: "zh-TW" },
  { name: "Chinese (Simplified, Singapore)", code: "zh-SG" },
  { name: "Croatian", code: "hr" },
  { name: "Czech", code: "cs" },
  { name: "Danish", code: "da" },
  { name: "Dutch (Netherlands)", code: "nl-NL" },
  { name: "Dutch (Belgium)", code: "nl-BE" },
  { name: "English (United States)", code: "en-US" },
  { name: "English (United Kingdom)", code: "en-GB" },
  { name: "English (India)", code: "en-IN" },
  { name: "English (Canada)", code: "en-CA" },
  { name: "English (Australia)", code: "en-AU" },
  { name: "English (New Zealand)", code: "en-NZ" },
  { name: "English (South Africa)", code: "en-ZA" },
  { name: "French (France)", code: "fr-FR" },
  { name: "French (Canada)", code: "fr-CA" },
  { name: "German (Germany)", code: "de-DE" },
  { name: "German (Switzerland)", code: "de-CH" },
  { name: "Greek", code: "el" },
  { name: "Hebrew", code: "he" },
  { name: "Hindi", code: "hi" },
  { name: "Hungarian", code: "hu" },
  { name: "Indonesian", code: "id" },
  { name: "Italian (Italy)", code: "it-IT" },
  { name: "Italian (Switzerland)", code: "it-CH" },
  { name: "Japanese", code: "ja" },
  { name: "Kannada", code: "kn" },
  { name: "Korean", code: "ko" },
  { name: "Malay (Malaysia)", code: "ms-MY" },
  { name: "Malay (Brunei)", code: "ms-BN" },
  { name: "Norwegian (Bokmål)", code: "nb" },
  { name: "Norwegian (Nynorsk)", code: "nn" },
  { name: "Persian", code: "fa" },
  { name: "Polish", code: "pl" },
  { name: "Portuguese (Portugal)", code: "pt-PT" },
  { name: "Portuguese (Brazil)", code: "pt-BR" },
  { name: "Punjabi (India)", code: "pa-IN" },
  { name: "Punjabi (Pakistan)", code: "pa-PK" },
  { name: "Russian", code: "ru" },
  { name: "Spanish (Spain)", code: "es-ES" },
  { name: "Spanish (Mexico)", code: "es-MX" },
  { name: "Spanish (Argentina)", code: "es-AR" },
  { name: "Spanish (Colombia)", code: "es-CO" },
  { name: "Swahili", code: "sw" },
  { name: "Swedish (Sweden)", code: "sv-SE" },
  { name: "Tamil (India)", code: "ta-IN" },
  { name: "Tamil (Sri Lanka)", code: "ta-LK" },
  { name: "Thai", code: "th" },
  { name: "Turkish", code: "tr" },
  { name: "Ukrainian", code: "uk" },
  { name: "Urdu (Pakistan)", code: "ur-PK" },
  { name: "Urdu (India)", code: "ur-IN" },
  { name: "Vietnamese", code: "vi" },
  { name: "Welsh", code: "cy" },
];

// export const VideoCategoryData = [
//   "Autos & Vehicles",
//   "Comedy",
//   "Education",
//   "Entertainment",
//   "Film & Animation",
//   "Gaming",
//   "Howto & Style",
//   "Music",
//   "News & Politics",
//   "Nonprofits & Activism",
//   'People & Blogs',
//   "Pets & Animals",
//   "Science & Technology",
//   "Sports",
//   "Travel & Events",
// ]
