import { createContext, useContext, useState } from "react";

import { ExportCsv } from "../../Api/CreatorExportCsv";
import { useCreatorContext } from "./CreatorContext";

const CreatorFavoriteContext = createContext();

export const CreatorFavoriteProvider = ({ children }) => {

  const {  platFormSelectedItem } = useCreatorContext();

  const [creatorData, setCreatorData] = useState([]);


  const [creatorListModal, setCreatorListModal] = useState(false);
  const toggleCreatorListModal = () => setCreatorListModal((prev) => !prev);

  const [listAddModal, setListAddModal] = useState(false);
  const toggleListAddModal = () => setListAddModal((prev) => !prev);

  const [listName, setListName] = useState("");

  const [creatorList, setCreatorList] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);
  

  const handleCheckboxChange = (id) => {
    setSelectedLists((prevSelected) => {
      if (prevSelected.includes(id)) {
        // If the ID is already selected, remove it from both states
        setListToDelete((prevToDelete) => prevToDelete.filter((item) => item !== id));
        return prevSelected.filter((item) => item !== id);
      } else {
        // If the ID is not selected, add it to both states
        setListToDelete((prevToDelete) => [...prevToDelete, id]);
        return [...prevSelected, id];
      }
    });
  };

  const clearSelection = () => {
    setSelectedLists([]);
  };

  const [deleteListModal, setDeleteListModal] = useState(false);
  const toggleDeleteList = () => setDeleteListModal((prev) => !prev);

  const [listToDelete, setListToDelete] = useState([]);
  const [deleteTag, setDeleteTag] = useState("");

  // Open delete modal and set the list to delete
  const handleDeleteClick = (listId) => {
    setDeleteTag("list");
    setListToDelete([listId]);
    setSelectedLists([listId]);
    toggleDeleteList();
    toggleCreatorListModal();
  };

  const handleDelete = () => {
    setCreatorList((prevList) => {
      if (selectedLists.length) {
        return prevList.filter((list) => !selectedLists.includes(list.id));
      }
      return prevList.filter((list) => list.id !== listToDelete.id);
    });

    if (selectedLists.length) {
      clearSelection();
    }

    toggleDeleteList();
  };

  const onExitCreatorListCanvas = () => {
    setSearchList("");
    setSelectAll(false);
    setSearchSubList("");
  };

  const [searchList, setSearchList] = useState("");

  // Filter the creatorList based on the search term
  const filteredList = creatorList?.filter((list) => list?.name?.toLowerCase().includes(searchList?.toLowerCase()));

  const [tableRowAddModal, setTableRowAddModal] = useState(false);
  const toggleTableRowAddModal = () => setTableRowAddModal((prev) => !prev);

  const [tableSelect, setTableSelect] = useState("");

  const [isAllFilterChecked, setIsAllFilterChecked] = useState(false);
  const [selectedFilterRows, setSelectedFilterRows] = useState([]);

  const [isAllProfileChecked, setIsAllProfileChecked] = useState(false);
  const [selectedProfileRows, setSelectedProfileRows] = useState([]);

  const handleSelectAllRow = ({ e, tableId, data }) => {
    const isChecked = e.target.checked;
    if (tableId === "filters") {
      setTableSelect("filters");
      setIsAllFilterChecked(isChecked);
      if (isChecked) {
        // Select all creator objects
        setSelectedFilterRows([...creatorData]);
      } else {
        // Deselect all rows
        setSelectedFilterRows([]);
      }
    } else if (tableId === "profile") {
      setTableSelect("profile");
      setIsAllProfileChecked(isChecked);
      if (isChecked) {
        // Select all creator objects
        setSelectedProfileRows([...data]);
      } else {
        // Deselect all rows
        setSelectedProfileRows([]);
      }
    }
  };

  // Handle individual checkbox change
  const handleTableCheckboxChange = ({ id, tableId, data }) => {
    setTableSelect(tableId);
    if (tableId === "filters") {
      const selectedCreator = creatorData.find((item) => item.id === id); // Find the selected creator object
      const isAlreadySelected = selectedFilterRows.some((selected) => selected.id === id); // Check if it's already selected

      const updatedSelectedRows = isAlreadySelected
        ? selectedFilterRows.filter((row) => row.id !== id) // Remove the selected creator if already in the list
        : [...selectedFilterRows, selectedCreator]; // Add the creator object if not already selected

      setSelectedFilterRows(updatedSelectedRows);

      // If all rows are selected, check the main checkbox
      setIsAllFilterChecked(updatedSelectedRows.length === creatorData.length);
    } else if (tableId === "profile") {
      const selectedCreator = data.find((item) => item.id === id); // Find the selected creator object
      const isAlreadySelected = selectedProfileRows.some((selected) => selected.id === id); // Check if it's already selected

      const updatedSelectedRows = isAlreadySelected
        ? selectedProfileRows.filter((row) => row.id !== id) // Remove the selected creator if already in the list
        : [...selectedProfileRows, selectedCreator]; // Add the creator object if not already selected

      setSelectedProfileRows(updatedSelectedRows);

      // If all rows are selected, check the main checkbox
      setIsAllProfileChecked(updatedSelectedRows.length === data.length);
    }
  };

  // const exportTableCsv = (tableId) => {
  //   const selectedColumns = ["full_name", "follower_count", "avg_engagement", "is_verified", "content_count", "avg_like_count", "avg_comment_count", "avg_view_count"];
  //   if (tableId === "filters") {
  //     selectedFilterRows?.length && ExportCsv(selectedFilterRows, "creators", selectedColumns);
  //     setSelectedFilterRows([])
  //     setIsAllFilterChecked(false)
  //   } else if (tableId === "profile") {
  //     selectedProfileRows?.length && ExportCsv(selectedProfileRows, "creators", selectedColumns);
  //     setSelectedProfileRows([])
  //     setIsAllProfileChecked(false)
  //   }
  // };

  const exportTableCsv = (tableId) => {
  const followerHead = platFormSelectedItem?.title === "instagram";
  const instagramColumns = ["full_name", "follower_count", "avg_engagement", "avg_view_count" , "avg_like_count", "avg_comment_count"];
  const youtubeColumns = ["full_name", "follower_count", "avg_engagement", "avg_view_count", "avg_like_count", "avg_comment_count"];

  const selectedColumns = followerHead ? instagramColumns : youtubeColumns;
  
    let selectedRows = [];
    if (tableId === "filters") {
      selectedRows = selectedFilterRows;
    } else if (tableId === "profile") {
      selectedRows = selectedProfileRows;
    }
  
    if (selectedRows.length > 25) {
      alert("You can only export a maximum of 25 rows at a time.");
      return;
    }
  
    if (selectedRows.length) {
      ExportCsv(selectedRows, "creators", selectedColumns);
  
      // Clear selected rows after export
      if (tableId === "filters") {
        setSelectedFilterRows([]);
        setIsAllFilterChecked(false);
      } else if (tableId === "profile") {
        setSelectedProfileRows([]);
        setIsAllProfileChecked(false);
      }
    } else {
      alert("Please select rows to export.");
    }
  };
  

  // const [favoritesCsvData,setFavoritesCsvData]= useState(null);
  const exportFavoriteListCsv = (lists, includeHeaders, inObject) => {
    const selectedColumns = ["full_name", "follower_count", "avg_engagement", "platform", "is_verified","avg_view_count", "avg_like_count", "avg_comment_count", ];
    const data = inObject === true ? [lists] : lists.creators;

   // Transform the data to update "youtube---data_api" to "youtube"
  const transformedData = data.map(item => ({
    ...item,
    platform: item.platform === "youtube---data_api" ? "youtube" : item.platform
  }));
    const name = inObject === true ? "creators" : lists.name;

    ExportCsv(transformedData, name, selectedColumns, includeHeaders);

  };

  const [subList, setSubList] = useState(null);

  const handleListClick = (list) => {
    setDeleteTag("list");
    setSubList(list);
    setSelectedLists([]);

  };

  const [selectedSubLists, setSelectedSubLists] = useState([]);
  const [selectAll, setSelectAll] = useState(false); // State for select all checkbox

  // Function to handle individual checkbox changes
  const handleIndividualCheckboxChange = (id) => {
    setSingleDelete(false);
    if (selectedSubLists.includes(id)) {
      setSelectedSubLists(selectedSubLists.filter((item) => item !== id));
      setListToDelete(selectedSubLists.filter((item) => item !== id));
    } else {
      setSelectedSubLists([...selectedSubLists, id]);
      setListToDelete([...selectedSubLists, id]);
    }
  };

  // Function to handle "select all" checkbox change
  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedSubLists([]);
      setListToDelete([]);
    } else {
      const allIds = filteredSubList?.map((item) => item.id) || [];
      setSelectedSubLists(allIds);
      setListToDelete(allIds);
    }
    setSelectAll(!selectAll);
  };

  const clearSubListSelection = () => {
    setSelectedSubLists([]);
    setListToDelete([]);
  };

  const [searchSubList, setSearchSubList] = useState("");

  // Filter the creatorSubList based on the search term
  const filteredSubList = subList?.creators?.filter((list) => {
    const fullNameMatch = list?.full_name?.toLowerCase().includes(searchSubList?.toLowerCase());
    const profileNameMatch = list?.platform_profile_name?.toLowerCase().includes(searchSubList?.toLowerCase());

    // Return true if either full_name or platform_profile_name matches the search term
    return fullNameMatch || profileNameMatch;
  });

  const [singleDelete, setSingleDelete] = useState(false);

  const handleInfluencerDeleteClick = (influencerId) => {
    setDeleteTag("creator");
    setSingleDelete(true);
    setSelectedSubLists([influencerId]);
    toggleDeleteList();
    toggleCreatorListModal();
  };

  // const [favoritesData,setFavoritesData] = useState([])

  // const getFavoritesList = async ({ token, setFavoriteLoading }) => {
  //   setFavoriteLoading(true);
  //   try {
  //     const headers = token ? { "x-access-token": token } : {};
  //     const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${API.creator.getFavoritesListApi}`, { headers });
  //     setFavoritesData(response?.data);
  //   } catch (error) {
  //     toast.error("Error fetching data");
  //   } finally {
  //     setFavoriteLoading(false);
  //   }
  // };

  return <CreatorFavoriteContext.Provider value={{ setTableRowAddModal, listToDelete, setCreatorList, selectedProfileRows, isAllProfileChecked, tableSelect, setTableSelect, selectedFilterRows, setSubList, exportFavoriteListCsv, creatorData, setCreatorData, exportTableCsv, setSingleDelete, setSelectedLists, setSelectedSubLists, setListToDelete, singleDelete, handleInfluencerDeleteClick, deleteTag, setDeleteTag, selectAll, setSelectAll, handleSelectAllChange, handleIndividualCheckboxChange, clearSubListSelection, selectedSubLists, setSearchSubList, searchSubList, filteredSubList, handleListClick, subList, isAllFilterChecked, handleTableCheckboxChange, handleSelectAllRow, toggleTableRowAddModal, tableRowAddModal, onExitCreatorListCanvas, filteredList, searchList, setSearchList, handleDelete, handleDeleteClick, toggleDeleteList, deleteListModal, selectedLists, clearSelection, handleCheckboxChange, listName, setListName, listAddModal, toggleListAddModal, creatorListModal, toggleCreatorListModal, creatorList, setIsAllFilterChecked, setSelectedFilterRows, setIsAllProfileChecked, setSelectedProfileRows }}>{children}</CreatorFavoriteContext.Provider>;
};

export const useCreatorFavoriteContext = () => {
  return useContext(CreatorFavoriteContext);
};
