import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup } from "reactstrap";
import { Btn, Image } from "../../../AbstractElement";
import { dynamicImage } from "../../../Helper";
import { useAuthContext } from "../../../Context/Auth/AuthContext";
import { yupResolver } from "@hookform/resolvers/yup";


export const ChnagePassword = () => {
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowCurrentPassword = () => setShowCurrentPassword(!showCurrentPassword);
  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () => setShowConfirmPassword(!showConfirmPassword);

  const { ChangePasswordSchema, handleChangePassword } = useAuthContext();
  const { register, handleSubmit, formState: { errors }, setError } = useForm({
    resolver: yupResolver(ChangePasswordSchema),
  });

  const onSubmit = (data) => {  
    handleChangePassword(data);
  };

  return (
    <Fragment>
      <div className="change-password">
        <div className="card">
          <div className="card-header text-start">
            <h4 className="mb-0">Reset Your Password</h4>
          </div>
          <div className="card-body">
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
              <FormGroup className="position-relative mb-4">
                <label htmlFor="currentPassword" className="form-label">
                  Current Password <span className="text-danger">*</span>
                </label>
                <input
                  {...register("currentPassword")}
                  type={showCurrentPassword ? "text" : "password"}
                  className={`form-control ${errors.currentPassword ? "invalid-field" : ""}`}
                  id="currentPassword"
                  placeholder="Enter Current Password"
                />
                {errors.currentPassword && (
                  <span className="required-note">{errors.currentPassword.message}</span>
                )}
                <div className="password-wrap" onClick={toggleShowCurrentPassword}>
                  <Image
                    src={dynamicImage(`svg/${showCurrentPassword ? "eye" : "eye-slash"}.svg`)}
                    className="form-icons"
                  />
                </div>
              </FormGroup>

              <FormGroup className="position-relative mb-4">
                <label htmlFor="newPassword" className="form-label">
                  New Password <span className="text-danger">*</span>
                </label>
                <input
                  {...register("newPassword")}
                  type={showNewPassword ? "text" : "password"}
                  className={`form-control ${errors.newPassword ? "invalid-field" : ""}`}
                  id="newPassword"
                  placeholder="Enter New Password"
                />
                {errors.newPassword && (
                  <span className="required-note">{errors.newPassword.message}</span>
                )}
                <div className="password-wrap" onClick={toggleShowNewPassword}>
                  <Image
                    src={dynamicImage(`svg/${showNewPassword ? "eye" : "eye-slash"}.svg`)}
                    className="form-icons"
                  />
                </div>
              </FormGroup>

              <FormGroup className="position-relative mb-4">
                <label htmlFor="confirmPassword" className="form-label">
                  Confirm Password <span className="text-danger">*</span>
                </label>
                <input
                  {...register("confirmPassword")}
                  type={showConfirmPassword ? "text" : "password"}
                  className={`form-control ${errors.confirmPassword ? "invalid-field" : ""}`}
                  id="confirmPassword"
                  placeholder="Confirm New Password"
                />
                {errors.confirmPassword && (
                  <span className="required-note">{errors.confirmPassword.message}</span>
                )}
                <div className="password-wrap" onClick={toggleShowConfirmPassword}>
                  <Image
                    src={dynamicImage(`svg/${showConfirmPassword ? "eye" : "eye-slash"}.svg`)}
                    className="form-icons"
                  />
                </div>
              </FormGroup>

              <div className="d-flex justify-content-end">
                <Btn block className="btn-theme d-inline-block w-auto" type="submit">
                  Update Password
                </Btn>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
