import React, { useState } from "react";
import { TextField } from "@mui/material";
import { WarningAmberRounded } from "@mui/icons-material";
import { Form, Modal, ModalBody, ModalHeader } from "reactstrap";
import toast from "react-hot-toast";

import { API } from "../../../Api/ApiList";
import { Btn, Image } from "../../../AbstractElement";
import { CreatorExportCsv } from "../../../Api/CreatorExportCsv";
import { dynamicImage, formatInstagramNumber } from "../../../Helper";
import { useCreatorContext } from "../../../Context/Creator/CreatorContext";
import { useAutoFocus } from "../../../Hooks/useAutoFocus";
import { useGetApi } from "../../../Api/useGetApi";
import { useCreatorProfileContext } from "../../../Context/Creator/CreatorProfileContext";

const ExportModal = ({ total, params, tableId }) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [exportNumber, setExportNumber] = useState("");
  const { exportModal, toggleExportCsv, platFormSelectedItem } = useCreatorContext();
  const inputRef = useAutoFocus(exportModal);
  const { creatorProfileState } = useCreatorProfileContext();
  const { searchQuery } = creatorProfileState;

  const followerHead = platFormSelectedItem?.title === "instagram";
  const instagramColumns = ["full_name", "follower_count","avg_engagement","avg_view_count", "avg_like_count", "avg_comment_count", ];
  const youtubeColumns = ["full_name", "follower_count", "avg_engagement", "avg_view_count", "avg_like_count", "avg_comment_count"];

  const selectedColumns = followerHead ? instagramColumns : youtubeColumns;

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Reset error states if value is empty
    if (value === "") {
      setIsInvalid(false);
      setErrorMessage("");
      setExportNumber("");
      return;
    }

    const numericValue = Number(value);

    if (/^\d*$/.test(value)) {
      setExportNumber(value);

      // Check if the value is greater than 100
      if (numericValue > 25) {
        setIsInvalid(true);
        setErrorMessage("You can export a maximum of 25 creators at a time.");
      }
      // Check if the value exceeds the total available creators
      else if (numericValue > total) {
        setIsInvalid(true);
        setErrorMessage(`You can only export up to ${total} creators.`);
      }
      // Otherwise, reset error
      else {
        setIsInvalid(false);
        setErrorMessage("");
      }
    }
  };

  const updatedParams = tableId === "filters" ? { ...params, page: 1, limit: exportNumber } : { ...params, platform:   platFormSelectedItem?.title  || null, platform_username: searchQuery, limit: exportNumber };

  const { refetch, fetchStatus } = useGetApi({ url: API.creator.findCreatorApi, params: updatedParams, options: { enabled: false, refetchOnWindowFocus: false } });
  const isLoading = fetchStatus === "fetching" || false;

  const exportToCSV = async (e) => {
    e.preventDefault();

    // Validate if exportNumber is valid
    if (exportNumber === "") {
      setIsInvalid(true);
      setErrorMessage("Please enter a valid number of creators to export.");
      return;
    }

    const numericExportNumber = Number(exportNumber);
    if (numericExportNumber <= total && numericExportNumber <= 100) {
      try {
        const response = await refetch();
        const creatorData = response?.data?.data;
        CreatorExportCsv(creatorData, "influencer's", selectedColumns, params);
        toggleExportCsv();
      } catch (error) {
        toast.error("Error exporting CSV");
      }
    } else {
      setIsInvalid(true);
      if (numericExportNumber > 100) {
        setErrorMessage("You can export a maximum of 100 creators at a time.");
      } else if (numericExportNumber > total) {
        setErrorMessage(`You can only export up to ${total} creators.`);
      }
    }
  };

  return (
    <Modal className="creator-addlist" centered isOpen={exportModal} toggle={toggleExportCsv}>
      <ModalHeader toggle={toggleExportCsv} className="border-0"></ModalHeader>
      <ModalBody>
        <div>
          <div className="list-wrap">
            <div className="list-icon">
              <Image src={dynamicImage("svg/export.svg")} alt="add-list Icon" />
            </div>
            <div className="list-detail">
              <h5>Export results as CSV</h5>
              <p>{total ? formatInstagramNumber(total) : 0} creators found</p>
            </div>
          </div>
          <Form onSubmit={exportToCSV}>
            <TextField inputRef={inputRef} fullWidth label="Enter number" placeholder="Number of creators needed for export" type="text" value={exportNumber} onChange={handleInputChange} inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }} />
            {isInvalid && (<p className="error-note"><WarningAmberRounded fontSize="medium" />{errorMessage}</p>)}
            <Btn type="submit" className="btn-theme mt-sm-4 mt-3 w-100" disabled={isInvalid || isLoading} loading={isLoading}>Export CSV</Btn>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ExportModal;
