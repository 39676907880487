import { AlternateEmail, Clear, InfoOutlined } from "@mui/icons-material";
import {
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Form } from "reactstrap";

import { Btn, Image } from "../../../../AbstractElement";
import { getParams, useSetUrlParams } from "../../../../Api/UrlParams";
import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";
import { dynamicImage } from "../../../../Helper";

const CreatorAiFilter = ({
  setParams,
  loading,
  setUrlParamsProcessed,
  setPage,
  setLimit,
}) => {
  const queryParams = new URLSearchParams(window.location.search);
  const platform = queryParams.get("platform"); // "youtube"
  const { aiSearch, setAiSearch, platFormSelectedItem , setSaveSearch,searchType, setSearchType} = useCreatorContext();
  console.log("searchType",searchType);
  const setParam = useSetUrlParams();
  const getParam = getParams();
  // const [searchType, setSearchType] = useState("onlyText"); // "onlyText" or "textImage"

  useEffect(() => {
    if (getParam.brand) {
      setAiSearch(getParam.brand);
    } else {
      setAiSearch("");
    }
  }, [getParam.brand, setAiSearch]);

  const submitSearch = async (e) => {
    e.preventDefault();
    setUrlParamsProcessed(true);
    setParams((prevParams) => ({
      ...prevParams,
      brand: aiSearch,
      platform: platFormSelectedItem.title,
      searchtype :searchType
    }));
    setPage(1);
    setLimit(25);
    setSaveSearch((prevParams) => ({
      ...prevParams,
      brand: aiSearch,
      platform: platFormSelectedItem.title,
      searchtype :searchType
    }));

    setParam({ brand: aiSearch, platform: platFormSelectedItem.title , searchType});

  };

  return (
    <div className="mb-4">
      {platFormSelectedItem?.title === "instagram" &&
        <div className="ai-search-panel mb-1">
          <h5>{"AI-Powered search"}</h5>
          <span className="taglNews">New</span>
        </div>
      }
      <div className="w-100">
        <div className="top-filter-panel w-100 h-100 mb-4">
          <Form className="form-search-box h-100 w-100" noValidate onSubmit={submitSearch}>
              <OutlinedInput
                disabled={false}
                startAdornment={<AlternateEmail className="info-icon" position="start" />}
                endAdornment={
                  aiSearch.length > 0 && (
                    <Clear className="cursor-pointer" onClick={() => setAiSearch("")} />
                  )
                }
                className="w-100 me-0 ai-input"
                placeholder={platform === "youtube" ? "Please enter your active youtube handle e.g. yourbrand ..." : "Please enter your active instagram handle e.g. yourbrand ..."}
                value={aiSearch}
                onChange={(e) => setAiSearch(e.target.value)}
              />
          
            <Btn
              className="btn-theme search-btn h-100"
              type="submit"
              disabled={!aiSearch.length || loading}
            >
              <Image
                src={dynamicImage("svg/search.svg")}
                className="search-icon"
                alt="Settings Icon"
              />{" "}
              Search
            </Btn>
          </Form>
        </div>
      </div>
      <div className="ai-search-panel mb-1">
        {platform === "youtube" &&
          <>
            <h5>{"Refine your search with"}</h5><span className="taglNews">New</span>
            <FormControl component="fieldset" className="search-type-options">
              <RadioGroup
                row
                value={searchType}
                onChange={(e) => setSearchType(e.target.value)}
              >
                <FormControlLabel
                  value="onlyText"
                  control={<Radio />}
                  label="Only Text"
                />
                <FormControlLabel
                  value="textImage"
                  control={<Radio />}
                  label="Text + Image"
                />
              </RadioGroup>
            </FormControl>
          </>
        }

      </div>
    </div>
  );
};

export default CreatorAiFilter;