import { ExpandMore } from "@mui/icons-material";
import { Autocomplete, TextField } from "@mui/material";
import React, { Fragment } from "react";
import { Col } from "reactstrap";

import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";
import AutocompleteChips from "../../../Common/AutocompleteChips";
import FilterWrapper from "../../../Common/FilterWrapper";
import InputMultipleAddCommon from "../../../Common/InputMultipleAddCommon";
import { countriesList } from "../../../../Data/CountryData";
import { VideoCategoryData, videoLanguagesOptions } from "../../../../Data/VideoFilterData";
import CustomRangeFromTo from "../../../Common/CustomRangeFromTo";
import { creatorContentOption, creatorFollowerOption } from "../../../../Data/FindCreatorData";
import { checkActiveRang } from "../../../../Helper";

export const CreatorsFilterList = ({ topicIsLoading, tagIsLoading, categoryIsLoading, videoCategoryIsLoading }) => {
  const { contentFromOptions, categoryOption, contentToOptions, filteredToOptions, followerRange, setFollowerRange, videoLanguagesInputValue, filteredFromOptions, setVideoLanguagesInputValue, videoLanguages, setVideoLanguages, tagsList, setTagsList, tagsOption, tagsParam, setTagsParam, channelTopicList, setChannelTopicList, channelTopicParam, setChannelTopicParam, channelTopicOption, videoCategoryInputValue, setVideoCategoryInputValue, videoCategoryMention, setVideoCategoryMention, videoDuration, setVideoDuration, postKeyInputValue, setPostKeyInputValue, postKey, setPostKey, creatorLocation, setCreatorLocation, creatorLocationInputValue, setCreatorLocationInputValue, platFormSelectedItem, setAccountType, accountType, accountInputValue, creatorVerify, setCreatorVerify, setAccountInputValue, contentValue, setContentValue, recentPosts, setRecentPosts, bioKey, bioKeyInputValue, setBioKeyInputValue, categoryMention, setCategoryMention, categoryInputValue, setCategoryInputValue, hashtags, setHashtags, hashTagInputValues, setHashTagInputValues, mentionInputValues, setMentionInputValues, mentionsTag, setMentionsTags, setBioKey , videoCategoryOption} = useCreatorContext();

  const isInstagram = platFormSelectedItem?.title === "instagram";
  const label = isInstagram ? "Followers" : "Subscribers";
  const toolTip = `Filter creators by ${isInstagram ? "follower" : "subscriber"} count.`;

  // // Extracting video category titles from VideoCategoryData
  // const videoCategoryOptions = VideoCategoryData[0].items.map((item) => item.snippet.title);
  const videoCategoryOptions = VideoCategoryData[0].items
  .map((item) => item.snippet.title)
  .sort((a, b) => a.localeCompare(b)); // Sort in ascending order


  return (
    <Col sm={6}>
      <div className="filter-box">
        <h5 className="inner-list">Creator</h5>
        <ul className="menu-list-wrap">
          <FilterWrapper title={label} toolTip={toolTip} selected={checkActiveRang(followerRange)}>
            <CustomRangeFromTo value={followerRange} setValue={setFollowerRange} filteredFromOptions={filteredFromOptions} filteredToOptions={filteredToOptions} options={creatorFollowerOption} />
          </FilterWrapper>

          {platFormSelectedItem?.title === "instagram" && (
            <Fragment>
              <FilterWrapper title="Categories" taglNews={true} toolTip="Filter creators by category." selected={categoryMention.length}>
                <AutocompleteChips options={categoryOption} value={categoryMention} setValue={setCategoryMention} inputValue={categoryInputValue} setInputValue={setCategoryInputValue} placeholder="Search categories ..." isLoading={categoryIsLoading} />
              </FilterWrapper>
            </Fragment>
          )}

          <FilterWrapper title="Hashtags" toolTip="Show creators who have mentioned the added hashtags." selected={hashtags.length > 0}>
            <div className="select-inner-box">
              <InputMultipleAddCommon title="Hashtags" inputValue={hashTagInputValues} setInputValue={setHashTagInputValues} items={hashtags} setItems={setHashtags} multiple={true} />
            </div>
          </FilterWrapper>

          <FilterWrapper title="Bio keyword" toolTip="Filter creators by the phrase they use in their bio." selected={bioKey}>
            <InputMultipleAddCommon title="Bio keyword" inputValue={bioKeyInputValue} setInputValue={setBioKeyInputValue} items={bioKey} setItems={setBioKey} multiple={false} />
          </FilterWrapper>


          {platFormSelectedItem?.title === "instagram" && (
            <Fragment>
              <FilterWrapper title="Is Verified?" toolTip="Filter creators by platform verification status." selected={creatorVerify}>
                <div className="select-inner-box">
                  <Autocomplete value={creatorVerify} popupIcon={<ExpandMore fontSize="small" />}
                    options={["Yes", "No"]}
                    renderInput={(params) => <TextField {...params} placeholder="is Verify" />}
                    onChange={(e, verify) => { setCreatorVerify(verify); }}
                  />
                </div>
              </FilterWrapper>
            </Fragment>
          )}


          <FilterWrapper title="Number of Content" toolTip="Filter creators by the number of contents." selected={checkActiveRang(contentValue)}>
            <CustomRangeFromTo value={contentValue} setValue={setContentValue} filteredFromOptions={contentFromOptions} filteredToOptions={contentToOptions} options={creatorContentOption} />
          </FilterWrapper>

          <FilterWrapper title="Most recent post" toolTip="Filter creators who posted content in the selected time frame." selected={recentPosts}>
            <div className="select-inner-box">
              <Autocomplete value={recentPosts} popupIcon={<ExpandMore fontSize="small" />}
                options={["<7 days", "<1 month", "<3 months", "<6 months"]}
                renderInput={(params) => <TextField {...params} placeholder="Select recent post" />}
                onChange={(e, recent) => { setRecentPosts(recent); }}
              />
            </div>
          </FilterWrapper>

          {platFormSelectedItem?.title === "instagram" && (
            <FilterWrapper title="Mentions" toolTip="Show creators who have mentioned the added username." selected={mentionsTag.length > 0}>
              <div className="select-inner-box">
                <InputMultipleAddCommon title="Mentions" inputValue={mentionInputValues} setInputValue={setMentionInputValues} items={mentionsTag} setItems={setMentionsTags} multiple={true} />
              </div>
            </FilterWrapper>
          )}

          {platFormSelectedItem?.title === "instagram" && (
            <Fragment>
              <FilterWrapper disabled title="Account type" toolTip="Filter creators by account type." selected={accountType.length}>
                <AutocompleteChips options={["Business", "Creator", "Personal"]} value={accountType} setValue={setAccountType} inputValue={accountInputValue} setInputValue={setAccountInputValue} placeholder="Search account type ..." />
              </FilterWrapper>
            </Fragment>
          )}

          {platFormSelectedItem?.title === "youtube" && (
            <Fragment>
              <FilterWrapper title="Channel Topics" toolTip="Filter creators by topics added to their channel." selected={channelTopicList.length}>
                <AutocompleteChips options={channelTopicOption} value={channelTopicList} setValue={setChannelTopicList} inputValue={channelTopicParam} setInputValue={setChannelTopicParam} placeholder="Search channel topics ..." isLoading={topicIsLoading} />
              </FilterWrapper>

              <FilterWrapper title="Tags" toolTip="Filter creators by subscribers tags." selected={tagsList.length}>
                <AutocompleteChips options={tagsOption} value={tagsList} setValue={setTagsList} inputValue={tagsParam} setInputValue={setTagsParam} placeholder="Search tags ..." isLoading={tagIsLoading} />
              </FilterWrapper>
            </Fragment>
          )}

          {platFormSelectedItem?.title === "youtube" && (
            <Fragment>
              <FilterWrapper title="Post keyword" toolTip="Filter creators by the phrase they use in their post." selected={postKey}>
                <InputMultipleAddCommon title="Post keyword" inputValue={postKeyInputValue} setInputValue={setPostKeyInputValue} items={postKey} setItems={setPostKey} multiple={false} />
              </FilterWrapper>
              <FilterWrapper title="Creator's location" toolTip="Filter creators who is from the selected location." selected={creatorLocation.length}>
                <AutocompleteChips options={countriesList} value={creatorLocation} setValue={setCreatorLocation} inputValue={creatorLocationInputValue} setInputValue={setCreatorLocationInputValue} placeholder="Search country ..." />
              </FilterWrapper>

              <FilterWrapper title="Video categories" toolTip="Filter creators by video category." selected={videoCategoryMention.length}>
                <AutocompleteChips options={videoCategoryOptions} value={videoCategoryMention} setValue={setVideoCategoryMention} inputValue={videoCategoryInputValue} setInputValue={setVideoCategoryInputValue} placeholder="Search video categories ..." />
              </FilterWrapper>

              <FilterWrapper title="Video duration" toolTip="Filter creators by video duration." selected={videoDuration}>
                <div className="select-inner-box">
                  <Autocomplete value={videoDuration} popupIcon={<ExpandMore fontSize="small" />}
                    options={["<30 seconds", "<60 seconds", "<90 seconds", "<5 minutes", "<15 minutes", "<1 hour", ">1 hour"]}
                    renderInput={(params) => <TextField {...params} placeholder="Select video duration" />}
                    onChange={(e, recent) => { setVideoDuration(recent); }}
                  />
                </div>
              </FilterWrapper>

              <FilterWrapper title="Video language" toolTip="Filter creators by video language." selected={videoLanguages.length}>
                <AutocompleteChips options={videoLanguagesOptions} value={videoLanguages} setValue={setVideoLanguages} inputValue={videoLanguagesInputValue} setInputValue={setVideoLanguagesInputValue} placeholder="Search video language ..." noCapital={true} />
              </FilterWrapper>
            </Fragment>
          )}

          {/* <FilterWrapper disabled title="Creator's gender" toolTip="Filter creators by gender of the creator.">
            <GenderCommon value={creatorGender} onChange={(newValue) => setCreatorGender(newValue)} />
          </FilterWrapper>
          <FilterWrapper disabled title="Creator's age" toolTip="Filter creators by creator's age.">
            <RangeFromToSlider min={13} max={75} step={1} value={creatorAgeValues} setValue={setCreatorAgeValues} />
          </FilterWrapper>
          <FilterWrapper disabled title="Creator's language" toolTip="Filter creators by creator language.">
            <AutocompleteChips label="Search language" options={["english", "spanish", "portuguese", "french", "arabic"]} value={creatorLanguage} setValue={setCreatorLanguage} inputValue={creatorLanguageInputValue} setInputValue={setCreatorLanguageInputValue} />
          </FilterWrapper>
          <FilterWrapper disabled title="Interest area" toolTip="Filter creators by interested area of the creator.">
            <AutocompleteChips label="Interest area" options={["fashion", "sports", "health", "finance", "technology"]} value={areaInterest} setValue={setAreaInterest} inputValue={areaInterestInputValue} setInputValue={setAreaInterestInputValue} />
          </FilterWrapper>
          <FilterWrapper disabled title="Brand mention" toolTip="Filter creators by mention brands.">
            <AutocompleteChips label="Brand mention" options={["nike", "makeMyTrip", "rebook", "apollo", "redBus"]} value={brandMention} setValue={setBrandMention} inputValue={brandMentionInputValue} setInputValue={setBrandMentionInputValue} />
          </FilterWrapper>
          <FilterWrapper disabled title="Est price" toolTip="Filter creators by estimated price.">
            <RangeFromToSlider min={100} max={50000} step={100} value={estValue} setValue={setEstValue} price="$" />
          </FilterWrapper> */}
        </ul>
      </div>
    </Col>
  );
};
