import React, { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Col } from "reactstrap";
import { dynamicImage, dynamicSvg, formatAndSortDates, formatAndSortDatesInIST, formatDateTimeNew, formatInstagramNumber, formatPercentage,sortDatesDescending,sortDatesDescendingWithTimestamp, sortedDates } from "../../Helper";
import { useCreatorContext } from "../../Context/Creator/CreatorContext";
import { Tooltip } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { countryMapping } from "../../Data/CountryMapping";
import About from "../Common/About";

export const CreatorPerformance = ({ user }) => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    const swiperRef = useRef(null);
    const { handleUrl } = useCreatorContext();

    useEffect(() => {
        if (swiperRef.current && prevRef.current && nextRef.current) {
            swiperRef.current.params.navigation.prevEl = prevRef.current;
            swiperRef.current.params.navigation.nextEl = nextRef.current;

            // Ensure navigation is properly initialized
            swiperRef.current.navigation.destroy(); // Clear previous settings
            swiperRef.current.navigation.init();    // Reinitialize
            swiperRef.current.navigation.update();  // Update navigation
        }
    }, [swiperRef, prevRef, nextRef]); // Add dependencies to trigger on changes


    // useEffect(() => {
    //     if (swiperRef.current) {
    //         swiperRef.current.params.navigation.prevEl = prevRef.current;
    //         swiperRef.current.params.navigation.nextEl = nextRef.current;
    //         swiperRef.current.navigation.destroy();
    //         swiperRef.current.navigation.init();
    //         swiperRef.current.navigation.update();
    //     }
    //     // }, [swiperRef, prevRef, nextRef]);
    // }, []);

    function truncateText(text, wordLimit) {
        const words = text?.split(' ');
        return words?.length > wordLimit ? words?.slice(0, wordLimit).join(' ') + '...' : text;
    }


    return <div>
        <Col lg={12}>
            <div className="row">
                <div className="col-xl-8">
                    <div className="custom-media-title mb-3">
                        <h4>Performance</h4>
                    </div>
                    <div className="custom-media-card">
                        <div className="row">
                            <div className="col-xl-6 mb-3">
                                <div className="card media-card">
                                    {user?.data?.influencer.platform === "instagram" ?
                                        <ul className="media-card-data" >
                                            <li className="share-button">
                                                <div  className="d-flex align-items-center gap-2">
                                                    Followers
                                                    {/* <Tooltip title={'Filter creators by follower count.'} placement={'top'}>
                                                        <span>
                                                            <InfoOutlined className="info-icon" fontSize="small" />
                                                        </span>
                                                    </Tooltip> */}
                                                </div>
                                                <span className="number-text">{formatInstagramNumber(user?.data?.influencer?.follower_count)}</span>
                                            </li>
                                            <li className="share-button">
                                                <div className="d-flex align-items-center gap-2">
                                                    Following
                                                    {/* <Tooltip title={'Filter creators by following count.'} placement={'top'}>
                                                        <span>
                                                            <InfoOutlined className="info-icon" fontSize="small" />
                                                        </span>
                                                    </Tooltip> */}
                                                </div>
                                                <span className="number-text">{formatInstagramNumber(user?.data?.influencer?.following_count)}</span>
                                            </li>
                                        </ul>
                                        :
                                        <ul className="media-card-data" >
                                            <li className="share-button">
                                                <div className="d-flex align-items-center gap-2">
                                                    Subcribers
                                                    {/* <Tooltip title={'Filter creators by subscriber count.'} placement={'top'}>
                                                        <span>
                                                            <InfoOutlined className="info-icon" fontSize="small" />
                                                        </span>
                                                    </Tooltip> */}
                                                </div>
                                                <span className="number-text">{formatInstagramNumber(user?.data?.influencer?.follower_count)}</span>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                            {user?.data?.influencer.platform === "youtube---data_api" &&
                                <div className="col-xl-6 mb-3">
                                    <div className="card media-card">
                                        <ul className="media-card-data" >
                                            <li className="share-button">
                                                <div className="d-flex align-items-center gap-2">
                                                   Content Count
                                                    {/* <Tooltip title={'Filter creators by content count.'} placement={'top'}>
                                                        <span>
                                                            <InfoOutlined className="info-icon" fontSize="small" />
                                                        </span>
                                                    </Tooltip> */}
                                                </div>
                                                <span className="number-text">{formatInstagramNumber(user?.data?.influencer?.content_count)}</span>
                                            </li>
                                            {/* <li className="share-button">
                                                <a href="#" className="d-flex align-items-center gap-2">
                                                    Watch Time
                                                    <Tooltip title={'Filter creators by watch time in hours count.'} placement={'top'}>
                                                        <span>
                                                            <InfoOutlined className="info-icon" fontSize="small" />
                                                        </span>
                                                    </Tooltip>
                                                </a>
                                                <span className="number-text">{user?.data?.influencer?.reputation_watch_time_in_hours}H</span>
                                            </li> */}
                                        </ul>
                                    </div>
                                </div>
                            }
                            <div className="col-md-6 mb-3">
                                <div className="card media-card">
                                    <ul className="media-card-data" >
                                        <li className="share-button">
                                            <div className="d-flex align-items-center gap-2">
                                                Avg. Engagement Rate
                                                <Tooltip title={user?.data?.influencer.platform === "youtube---data_api" ?'Average percentage of interaction (likes, comments) relative to the subscribers count.':'Average percentage of interaction (likes, comments) relative to the followers count.'} placement={'top'}>
                                                    <span>
                                                        <InfoOutlined className="info-icon" fontSize="small" />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            
                                            <span className="number-text">{formatPercentage(user?.data?.feature?.avg_engagement)}</span>
                                        </li>
                                        <li className="share-button">
                                            <div className="d-flex align-items-center gap-2">
                                                Avg. Views
                                                <Tooltip title={'Average number of views on top post.'} placement={'top'}>
                                                    <span>
                                                        <InfoOutlined className="info-icon" fontSize="small" />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <span className="number-text">{formatInstagramNumber(user?.data?.feature?.avg_view_count)}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="card media-card">
                                    <ul className="media-card-data" >
                                        <li className="share-button">
                                            <div className="d-flex align-items-center gap-2">
                                                Avg. Likes
                                                <Tooltip title={'Average number of likes received on top posts.'} placement={'top'}>
                                                    <span>
                                                        <InfoOutlined className="info-icon" fontSize="small" />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <span className="number-text">{formatInstagramNumber(user?.data?.feature?.avg_like_count)}</span>
                                        </li>
                                        <li className="share-button">
                                            <div className="d-flex align-items-center gap-2">
                                                Avg. Comments
                                                <Tooltip title={'Average number of comments received on top posts.'} placement={'top'}>
                                                    <span>
                                                        <InfoOutlined className="info-icon" fontSize="small" />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                            <span className="number-text">{formatInstagramNumber(user?.data?.feature?.avg_comment_count)}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 mb-3">
                    <div className="custom-media-title mb-3 custom-overview">
                        <h4>Overview</h4>
                    </div>
                    <ul className="card custom-media-card">
                        <About introduction={user?.data?.influencer?.introduction} />

                        {user?.data?.influencer.platform === "youtube---data_api" && user?.data?.influencer.gender &&
                            <li className="custom-details">
                                <h5>Gender:</h5>    
                                <span>{user?.data?.influencer.gender || "--"}</span>
                            </li>
                        }

                        {user?.data?.influencer.platform === "youtube---data_api" &&
                            <li className="custom-details">
                                <h5>Location:</h5>
                                <span>{countryMapping[user?.data?.influencer.country] || "--"}</span>
                            </li>
                        }

                        <li className="custom-details">
                            <h5 >Account Type:</h5>
                            <span>{user?.data?.influencer.is_professional_account ? "Professional Account" : "Business Account"}</span>
                        </li>

                        {/* <div className="user-location">     
                  <p>Male</p> 
                  <svg className="gender-icon"> 
                    <use href={`${dynamicSvg('admin-sprite.svg')}#stroke-gender`}></use>
                  </svg>
                  <Image className="img-fluid" src={dynamicImage("svg/")} alt="location" />
                </div>  */}
                        {/* <div className="custom-engagement">
                  <p>Mumbai, India</p> 
                  <Image className="img-fluid" src={dynamicImage("svg/location.svg")} alt="location" />
                </div>  */}
                        {/* <div className="custom-engagement">
                  <p>Creator</p> 
                  <svg className="gender-icon" >
                    <use href={`${dynamicSvg('admin-sprite.svg')}#stroke-ui-kits`}></use>
                  </svg>
                </div> */}
                    </ul>
                </div>
            </div>
            <div className="user-box mt-4">
                <div className="custom-media-title">
                    <h4 className="mb-2">Recent post</h4>
                </div>
                <div className="position-relative h-100 user-card-slider">
                    <Swiper
                        ref={swiperRef}
                        className="creators-slider h-100"
                        slidesPerView={3}
                        loop={false}
                        spaceBetween={20}
                        modules={[Navigation, Autoplay]}
                        autoplay={{ delay: 2500, disableOnInteraction: true }}
                        speed={1500}
                        breakpoints={{
                            320: {
                                slidesPerView: 1,
                            },
                            768: {
                                slidesPerView: 2,
                            },
                            1200: {
                                slidesPerView: 3,
                            },
                        }}
                        onInit={(swiper) => {
                            swiperRef.current = swiper;
                            if (swiper.navigation) {
                                swiper.params.navigation.prevEl = prevRef.current;
                                swiper.params.navigation.nextEl = nextRef.current;
                                swiper.navigation.init();
                                swiper.navigation.update();
                            }
                        }}
                    >
                        {user?.data?.engagements
                        .sort((a, b) => new Date(b?.published_at) - new Date(a?.published_at))// Sort engagements by published_at in ascending order
                        .slice(0, 9) 
                        .map((item) => (
                            <SwiperSlide className="h-100" key={item.id}>
                                <div className="top-post">
                                    <div className="d-flex" onClick={() => handleUrl(user?.data?.influencer)}>
                                        <p>
                                            <span>{formatDateTimeNew(item?.published_at)}</span>
                                        </p>
                                    </div>
                                    <div className="post-img">
                                        <a href={item.url} target="_blank" rel="noopener noreferrer">
                                            <img
                                                className="img-fluid"
                                                src={item?.thumbnail_url || dynamicImage("image.png")}
                                                alt="User"
                                                onError={(e) => {
                                                    e.target.src = dynamicImage("image.png");
                                                }}
                                            />
                                        </a>
                                    </div>
                                    <div className="post-description">
                                        <div className="custom-post-info">
                                            <div className="post-info-left">
                                                <svg className="media-icon">
                                                    <use href={`${dynamicSvg("admin-sprite.svg")}#blog-heart`}></use>
                                                </svg>
                                                <p>{formatInstagramNumber(item.engagement_like_count)}</p>
                                            </div>
                                            <div className="post-info-left">
                                                <svg className="media-icon">
                                                    <use href={`${dynamicSvg("admin-sprite.svg")}#comment-icon`}></use>
                                                </svg>
                                                <p>{formatInstagramNumber(item.engagement_comment_count)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-description">
                                        <p>{truncateText(item.description, 20)}</p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <button ref={prevRef} className="swiper-button-prev"></button>
                    <button ref={nextRef} className="swiper-button-next"></button>

                </div>
            </div>
        </Col>
    </div>;
};
