// import Signup from "../Component/Auth/Signup";
// import LogIn from "../Pages/Auth/Login";
// import FindCreator from "../Pages/FindCreator";
// import ForgetPassword from "../Pages/Auth/ForgetPassword";
// import EmailVerify from "../Pages/Auth/EmailVerify";
// import SummarizeDetails from "../Pages/Auth/SummarizeDetails";
// import ResetPassword from "../Pages/Auth/ResetPassword";
// import HomePage from "../Pages/Home";
// import Profile from "../Pages/User/Profile";
// import { AdminLogin } from "../Pages/Admin/Login";
// import { DBDetails } from "../Pages/Admin/DBDetails";

// export const ROUTES = {
//   Home: "/",
//   Auth: {
//     Signup: "/signup",
//     Login: "/login",
//     ForgetPassword: "/forget-password",
//     UpdatePassword: "/update_password",
//     EmailVerify: "/email-verify",
//     SummarizeDetails: "/summarize-details",
//   },
//   Pages: {
//     Creator: "/creator",
//     Brand: "/brand",
//     Collaboration: "/collaboration",
//     Resource: {
//       Blog: "/resource/blog",
//       Webinars: "/resource/webinars",
//       Glossary: "/resource/glossary"
//     },
//     Pricing:"/pricing"
//   },
//   User:{
//     MyProfile:"/user/my-profile",
//   },
//   Admin:{
//     AdminLogin:"/admin/login",
//     dbDetails:"/admin/loginActivity"
//   }
// };

// export const routes = [
//   { path: ROUTES.Home, element: <HomePage /> },
//   // { path: ROUTES.Pages.Brand, element: <FindBrand /> },
//   // { path: `${ROUTES.Pages.Brand}/:id`, element: <FindBrandDetails /> },
//   // { path: ROUTES.Pages.Collaboration, element: <Collaboration /> },
//   // { path: `${ROUTES.Pages.Collaboration}/:id`, element: <CollaborationDetails /> },
//   // { path: ROUTES.Pages.Resource.Blog, element: <Blog /> },
//   // { path: `${ROUTES.Pages.Resource.Blog}/:id`, element: <BlogDetails /> },
//   // { path: ROUTES.Pages.Resource.Webinars, element: <Webinars /> },
//   // { path: ROUTES.Pages.Resource.Glossary, element: <Glossary /> },
// ];

// export const AuthRoutes = [
//   { path: ROUTES.Auth.Signup, element: <Signup /> },
//   { path: ROUTES.Auth.Login, element: <LogIn /> },
//   { path: ROUTES.Admin.AdminLogin, element: <AdminLogin /> },
//   { path: ROUTES.Auth.ForgetPassword, element: <ForgetPassword /> },
//   { path: `${ROUTES.Auth.UpdatePassword}/:id`, element: <ResetPassword /> },
//   { path: ROUTES.Auth.EmailVerify, element: <EmailVerify /> },
//   { path: ROUTES.Auth.SummarizeDetails, element: <SummarizeDetails /> },
// ];

// export const ProtectedRoutes = [
//   { path: ROUTES.Pages.Creator, element: <FindCreator /> },
//   // { path: `${ROUTES.Pages.Creator}/:id`, element: <FindCreatorDetails /> },
//   // { path: ROUTES.Pages.Pricing, element: <Pricing /> },
// ];

// export const ProtectedProfileRoutes = [
//   { path: ROUTES.User.MyProfile, element: <Profile /> },
// ];

// export const AdminRoutes = [
//   { path: ROUTES.Admin.dbDetails, element: <DBDetails /> },
// ];

import Signup from "../Component/Auth/Signup";
import LogIn from "../Pages/Auth/Login";
import FindCreator from "../Pages/FindCreator";
import ForgetPassword from "../Pages/Auth/ForgetPassword";
import EmailVerify from "../Pages/Auth/EmailVerify";
import SummarizeDetails from "../Pages/Auth/SummarizeDetails";
import ResetPassword from "../Pages/Auth/ResetPassword";
import HomePage from "../Pages/Home";
import Profile from "../Pages/User/Profile";
import { AdminLogin } from "../Pages/Admin/Login";
import { DBDetails } from "../Pages/Admin/DBDetails";
import { BrandDetails } from "../Pages/Admin/BrandDetails";
import { AdminDashboard } from "../Pages/Admin/Dashboard";
import { CreatorProfiles } from "../Pages/Creator/Profile";
import { ChnagePassword } from "../Component/User/ChangePassword";

export const ROUTES = {
  Home: "/",
  Auth: {
    Signup: "/signup",
    Login: "/login",
    ForgetPassword: "/forget-password",
    UpdatePassword: "/update_password",
    EmailVerify: "/email-verify",
    SummarizeDetails: "/summarize-details",
  },
  Pages: {
    Creator: "/creator",
    Brand: "/brand",
    Collaboration: "/collaboration",
    Resource: {
      Blog: "/resource/blog",
      Webinars: "/resource/webinars",
      Glossary: "/resource/glossary",
    },
    Pricing: "/pricing",
    CreatorProfiles: "/creator-profile/:id"
  },
  User: {
    MyProfile: "/user/my-profile",
    ChangePassword:"/user/change-password"
  },
  Admin: {
    AdminLogin: "/admin/login",
    dashboard:"/admin/dashboard",
    dbDetails: "/admin/loginActivity",
    brandDetails:"/admin/brand"
  },
};

export const routes = [{ path: ROUTES.Home, element: <HomePage /> }];

export const AuthRoutes = [
  { path: ROUTES.Auth.Signup, element: <Signup /> },
  { path: ROUTES.Auth.Login, element: <LogIn /> },
  { path: ROUTES.Admin.AdminLogin, element: <AdminLogin /> },
  { path: ROUTES.Auth.ForgetPassword, element: <ForgetPassword /> },
  { path: `${ROUTES.Auth.UpdatePassword}/:id`, element: <ResetPassword /> },
  { path: ROUTES.Auth.EmailVerify, element: <EmailVerify /> },
  { path: ROUTES.Auth.SummarizeDetails, element: <SummarizeDetails /> },
];

export const ProtectedRoutes = [{ path: ROUTES.Pages.Creator, element: <FindCreator /> },{ path: ROUTES.Pages.CreatorProfiles, element: <CreatorProfiles /> }];

export const ProtectedProfileRoutes = [{ path: ROUTES.User.MyProfile, element: <Profile /> },{ path: ROUTES.User.ChangePassword, element: <ChnagePassword /> }];

export const AdminRoutes = [
  { path: ROUTES.Admin.dbDetails, element: <DBDetails /> },
  { path: ROUTES.Admin.brandDetails, element: <BrandDetails />},
  { path: ROUTES.Admin.dashboard, element: <AdminDashboard />},
];