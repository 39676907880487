import axios from "axios";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { createContext, useContext, useState } from "react";

import { ROUTES } from "../../Routes/Routes";
import { API, instagramApi } from "../../Api/ApiList";
import getStorage, { KEYS } from "../../Helper/getStorage";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const { getItem, setItem, clearItems } = getStorage();

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return getItem(KEYS.UAT) !== null;
  });

  // Check Password strength
  const strongPasswordRules = [(value) => value.length >= 8, (value) => /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value), (value) => /[a-z]/.test(value), (value) => /[A-Z]/.test(value), (value) => /\d/.test(value)];

  const getPasswordStrength = (password) => {
    const score = strongPasswordRules.reduce((acc, rule) => acc + (rule(password) ? 1 : 0), 0);

    if (score <= 2) {
      return "weak";
    } else if (score === 3 || score === 4) {
      return "medium";
    } else {
      return "strong";
    }
  };

  // Creators Signup
  const [signUpTab, setActiveSignUpTab] = useState(1);

  const CreatorSignUpSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone_number: Yup.string().required("phone is required"),
    password: Yup.string()
      .required("Minimum of 8 characters, including 1 lowercase, 1 capital, and 1 symbol.")
      .min(8, "Password must be at least 8 characters")
      .test("Password-strength", "Password is too weak", (value) => {
        const strength = getPasswordStrength(value);
        return strength === "strong" || strength === "medium";
      }),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
    // terms: Yup.bool().oneOf([true]).required(),
  });



  const handleSignUp = async ({ data, navigate, account, setLoading }) => {  
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${API.auth.registerApi}`, { name: data.name, email: data.email, phone_number: data.phone_number , password: data.password, account_type: account });
      if (response.status === 201 || response.status === 200) {
        setItem(KEYS.EMAIL, JSON.stringify(data.email));
        navigate(ROUTES.Auth.EmailVerify);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // Email Verification
  const handleEmailVerification = async ({ getEmail, otpCode, navigate, setIsError, setLoading }) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${API.auth.otpVerifyApi}`, { email: getEmail, otp: otpCode });
      if (response.status === 201 || response.status === 200) {
        const userData = response?.data?.data;
        setItem(KEYS.UAT, JSON.stringify(userData));
        setIsAuthenticated(true);

        const validToken = getItem(KEYS.UAT);
        if (validToken) {
          navigate(ROUTES.Home);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsError(true);
    } finally {
      setLoading(false);
    }
  };

  // Reset Code
  const handleReset = async ({ getEmail }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${API.auth.resendOtpApi}`, { email: getEmail });
      if (response.status === 201 || response.status === 200) {
        toast.success(response.data.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };



  // Login
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is a required"),
    password: Yup.string().required("Password is a required"),
  });

  const handleLogin = async ({ data, navigate, setLoading }) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${API.auth.loginApi}`, { email: data?.email, password: data?.password, recaptchaToken: data.recaptchaToken, });
      if (response.status === 201 || response.status === 200) {
        const userData = response?.data?.data;
        setItem(KEYS.UAT, JSON.stringify(userData));
        setIsAuthenticated(true);

        const validToken = getItem(KEYS.UAT);
        if (validToken) {
          navigate(ROUTES.Home);
        }
      }
    } catch (error) {
      ;
    } finally {
      setLoading(false);
    }
  };

  // Admin Login
    const handleAdminLogin = async ({ data, navigate, setLoading }) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${API.admin.adminLoginApi}`, { email: data?.email, password: data?.password });
      if (response.status === 201 || response.status === 200) {
        const userData = response?.data?.data;

        setItem(KEYS.UAT, JSON.stringify(userData));
        setIsAuthenticated(true);

        const validToken = getItem(KEYS.UAT);
        if (validToken) {
          navigate(ROUTES.Admin.dbDetails);
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // Forget Password
  const ForgetPassSchema = Yup.object().shape({
    email: Yup.string().email().required("Email is a required"),
  });

  const handleForgetPassword = async ({ data, navigate, setLoading }) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${API.auth.forgotPasswordApi}`, { email: data.email });
      if (response.status === 201 || response.status === 200) {
        toast.success(response.data.message);
        navigate(ROUTES.Auth.Login);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  // Reset Password
  const ResetPassSchema = Yup.object().shape({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .test((value) => {
        const strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&]).{8,}$/;
        return strongRegex.test(value);
      }),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleResetPassword = async ({ pathToken, data, navigate, setLoading }) => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${API.auth.resetPasswordApi}`, { token: pathToken, password: data.password });
      if (response.status === 201 || response.status === 200) {
        setItem(KEYS.RESET, JSON.stringify(true));
        navigate(ROUTES.Auth.Login);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  //Instagram
  const handleInstagramLogin = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${instagramApi}`);
      if (response.status === 201 || response.status === 200) {
        const redirectUrl = response.data.url;
        window.location.href = redirectUrl;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleSignOut = (navigate) => {
    navigate && navigate(ROUTES.Home);
    clearItems();
    setIsAuthenticated(false);
  };


// Password change schema (for change password)
const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "Password must be at least 8 characters")
    .test("Password-strength", "Password is too weak", (value) => {
      const strength = getPasswordStrength(value);
      return strength === "strong" || strength === "medium";
    }),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});


  // Handle Change Password function
  const handleChangePassword = async (data) => {
    const key =  KEYS.UAT;
    const { token } = JSON.parse(getItem(key));

    console.log("data", data,token);
    
    // setLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/${API.user.changePasswordApi}`,
        {
          old_password: data.currentPassword,
          password: data.newPassword,
        },
        {
          headers: {
            'x-access-token': token, // Pass the token in Authorization header
            "Content-Type": "application/json", // Specify content type
          },
        }
        
      );
      if (response.status === 200 || response.status === 201) {
        toast.success("Password changed successfully!");
        // navigate(ROUTES.Home); // Redirect after successful password change
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error changing password");
      // setIsError(true);
    } finally {
      // setLoading(false);
    }
  };

  // Handle Change Password function
  const handleUpdateProfile = async (data) => {
    const key =  KEYS.UAT;
    const { token } = JSON.parse(getItem(key));

    // setLoading(true);
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/${API.user.updateProfileApi}`,
        {
          name: data.name,
          email: data.email,
        },
        {
          headers: {
            'x-access-token': token, // Pass the token in Authorization header
            "Content-Type": "application/json", // Specify content type
          },
        }
        
      );
      if (response.status === 200 || response.status === 201) {
        toast.success("Profile Updated successfully!");
        // navigate(ROUTES.Home); // Redirect after successful password change
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Error changing password");
      // setIsError(true);
    } finally {
      // setLoading(false);
    }
  };

  
  return <AuthContext.Provider value={{ handleAdminLogin, setIsAuthenticated, signUpTab, setActiveSignUpTab, handleSignUp, handleLogin, LoginSchema, CreatorSignUpSchema, getPasswordStrength, ForgetPassSchema, handleForgetPassword, ResetPassSchema, handleResetPassword, handleEmailVerification, handleReset, handleInstagramLogin, handleSignOut, isAuthenticated , handleChangePassword,
    ChangePasswordSchema,handleUpdateProfile}}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
