import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Form, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap";

import { Btn } from "../../../../AbstractElement";
import { getParams, removeAllFilters, useSetUrlParams } from "../../../../Api/UrlParams";
import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";
import { CreatorsFilterList } from "./CreatorsFilterList";
import { PerformanceFilterList } from "./PerformanceFilterList";
import { countriesList } from "../../../../Data/CountryData";
import { videoLanguagesOptions } from "../../../../Data/VideoFilterData";
import { averageCommentOption, averageLikeOption, averageViewOption, creatorContentOption, creatorFollowerOption } from "../../../../Data/FindCreatorData";
import { checkActiveRang } from "../../../../Helper";

const FilterOffcanvas = ({ getAndSetParams, setUrlParamsProcessed, setDefaultSort, isLoading, topicIsLoading, tagIsLoading, categoryIsLoading, videoCategoryIsLoading }) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const getParam = getParams();
  const setParam = useSetUrlParams();
  const {setSearchType, setAverageViewValue, setContentValue, followerRange, setVideoLanguages, videoLanguages, tagsList, channelTopicList, videoDuration, setVideoCategoryMention, videoCategoryMention, postKey, setTagsList, creatorLocation, setCreatorLocation, accountType, categoryMention, setCategoryMention, setAccountType, platFormSelectedItem, averageViewValue, commentValue, likeValue, setLikeValue, contentValue, bioKey, mentionsTag, recentPosts, creatorVerify, engagementValue, hashtags, removeUrlParams, filterCanvas, toggleFilterCanvas, setCommentValue, setSaveSearch,aiSearch , searchType } = useCreatorContext();
  const { videoLanguage, videoCategory, tag, creatorLocations, category, accountTypes, creatorMinContent, creatorMaxContent, creatorMinLike, creatorMaxLike, creatorMinComment, creatorMaxComment, creatorMinView, creatorMaxView } = getParam;

  // Formatting mentions and hashtags
  const formatTags = (tags, prefix = "") => tags.map((tag) => tag.replace(new RegExp(`^${prefix}`), "")).join(",");

  const formattedMentions = formatTags(mentionsTag, "@");
  const formattedHashTag = formatTags(hashtags, "#");

  useEffect(() => {
    const shouldEnableButton = !!platFormSelectedItem?.title && (checkActiveRang(followerRange) || checkActiveRang(contentValue) || checkActiveRang(likeValue) || checkActiveRang(commentValue) || checkActiveRang(averageViewValue) || formattedHashTag || engagementValue > 0 || creatorVerify || recentPosts || videoDuration || formattedMentions || bioKey || postKey || channelTopicList.length > 0 || accountType.length > 0 || categoryMention.length > 0 || videoCategoryMention.length > 0 || creatorLocation.length > 0 || videoLanguages.length > 0 || tagsList.length > 0);
    setIsDisabled(!shouldEnableButton);
  }, [accountType.length, averageViewValue, bioKey, categoryMention.length, channelTopicList.length, commentValue, contentValue, creatorLocation.length, creatorVerify, engagementValue, followerRange, formattedHashTag, formattedMentions, likeValue, platFormSelectedItem?.title, postKey, recentPosts, tagsList.length, videoCategoryMention.length, videoDuration, videoLanguages.length]);

  useEffect(() => {
    if (accountTypes && accountTypes.length) {
      const accountTypesArray = accountTypes.split(",");
      setAccountType(accountTypesArray);
    }


    if (videoCategory && videoCategory.length) {
      const videoCategoryArray = videoCategory.split(",");
      setVideoCategoryMention(videoCategoryArray);
    }
  }, [accountTypes, category, setAccountType, setCategoryMention, setVideoCategoryMention, videoCategory, videoLanguages]);

  useEffect(() => {
    if (creatorLocations && creatorLocations.length) {
      const creatorLocationsArray = creatorLocations.split(",").map((code) => {
          const countryObject = countriesList.find((country) => country.code === code.trim());
          return countryObject ? `${countryObject.name}, ${countryObject.code}` : null;
        }).filter(Boolean);
      setCreatorLocation(creatorLocationsArray);
    }
  }, [creatorLocations, setCreatorLocation]);

  useEffect(() => {
    if (videoLanguage && videoLanguage.length) {
      const videoLanguageArray = videoLanguage.split(",").map((code) => {
          const videoLanguageObject = videoLanguagesOptions.find((country) => country.code === code.trim());
          return videoLanguageObject ? `${videoLanguageObject.name}, ${videoLanguageObject.code}` : null;
        }).filter(Boolean);
      setVideoLanguages(videoLanguageArray);
    }
  }, [setVideoLanguages, videoLanguage]);

  useEffect(() => {
    if (tag && tag.length) {
      const tagsArray = tag.split(",");
      setTagsList(tagsArray);
    }
  }, [setTagsList, tag]);

  // Set values based on URL params for Creator Content and average like
  useEffect(() => {
    const getTitle = (value, options) => options.find((item) => item.value === Number(value))?.title || null;

    const updateRange = (minValue, maxValue, options, setValue) => {
      const minTitle = getTitle(minValue, options);
      const maxTitle = getTitle(maxValue, options);

      // Update state only if at least one title is found
      if (minTitle || maxTitle) setValue({ from: minTitle, to: maxTitle });
    };

    // Update content range
    updateRange(creatorMinContent, creatorMaxContent, creatorContentOption, setContentValue);

    // Update likes range
    updateRange(creatorMinLike, creatorMaxLike, averageLikeOption, setLikeValue);

    // Update comment range
    updateRange(creatorMinComment, creatorMaxComment, averageCommentOption, setCommentValue);

    // Update view range
    updateRange(creatorMinView, creatorMaxView, averageViewOption, setAverageViewValue);
  }, [creatorMaxComment, creatorMaxContent, creatorMaxLike, creatorMaxView, creatorMinComment, creatorMinContent, creatorMinLike, creatorMinView, setAverageViewValue, setCommentValue, setContentValue, setLikeValue]);

  const removeAll = useCallback(async () => {
    setDefaultSort();
    removeAllFilters();
    removeUrlParams();
    toggleFilterCanvas();
  }, [removeUrlParams,setSearchType, toggleFilterCanvas]);

  const submitSearch = (e) => {
    e.preventDefault();
    const creatorLocationArray = creatorLocation.map((location) => location.split(", ").pop());
    const videoLanguageArray = videoLanguages.map((lang) => lang.split(", ").pop());
    
    const params = {
      platform: platFormSelectedItem?.title || null,
      hashtags: formattedHashTag || null,
      creatorMinFollower: followerRange.from !== null ? creatorFollowerOption.find((item) => item.title === followerRange.from)?.value : null,
      creatorMaxFollower: followerRange.to !== null ? creatorFollowerOption.find((item) => item.title === followerRange.to)?.value : null,
      engagementRate: engagementValue > 0 ? engagementValue : null,
      isVerify: creatorVerify || null,
      mostRecentPosts: recentPosts || null,
      mentions: formattedMentions || null,
      biokeyword: bioKey || null,
      creatorMinContent: contentValue.from !== null ? creatorContentOption.find((item) => item.title === contentValue.from)?.value : null,
      creatorMaxContent: contentValue.to !== null ? creatorContentOption.find((item) => item.title === contentValue.to)?.value : null,
      creatorMinLike: likeValue.from !== null ? averageLikeOption.find((item) => item.title === likeValue.from)?.value : null,
      creatorMaxLike: likeValue.to !== null ? averageLikeOption.find((item) => item.title === likeValue.to)?.value : null,
      creatorMinComment: commentValue.from !== null ? averageCommentOption.find((item) => item.title === commentValue.from)?.value : null,
      creatorMaxComment: commentValue.to !== null ? averageCommentOption.find((item) => item.title === commentValue.to)?.value : null,
      creatorMinView: averageViewValue.from !== null ? averageViewOption.find((item) => item.title === averageViewValue.from)?.value : null,
      creatorMaxView: averageViewValue.to !== null ? averageViewOption.find((item) => item.title === averageViewValue.to)?.value : null,
      accountTypes: accountType.length > 0 ? accountType : null,
      category: categoryMention.length > 0 ? categoryMention : null,
      creatorLocations: creatorLocation.length > 0 ? creatorLocationArray : null,
      postKeyWord: postKey || null,
      videoCategory: videoCategoryMention.length > 0 ? videoCategoryMention : null,
      videoDurations: videoDuration || null,
      videoLanguage: videoLanguages.length > 0 ? videoLanguageArray : null,
      channelTopic: channelTopicList.length > 0 ? channelTopicList : null,
      tag: tagsList.length > 0 ? tagsList : null,
    }

     // Filter out null values from the params object
     const filteredParams = Object.fromEntries(
      Object.entries(params).filter(([_, value]) => {
        return value !== null
      })
    );

    setParam(filteredParams);
    setUrlParamsProcessed(true);
    getAndSetParams();
    toggleFilterCanvas();

    setSaveSearch((filteredParams) => ({
      ...filteredParams,
      brand: aiSearch,
      platform: platFormSelectedItem.title,
      searchtype :searchType
    }));
  };

  return (
    <Fragment>
      <Offcanvas className="filter-list-offcanvas custom-filter-offcanvas" direction="end" isOpen={filterCanvas} toggle={toggleFilterCanvas}>
        <OffcanvasHeader toggle={toggleFilterCanvas}>All filters</OffcanvasHeader>
        <OffcanvasBody className="scroll-bar filter-body-wrap">
          <Form onSubmit={submitSearch} noValidate>
            <Row className="filter-row g-4 scroll-bar">
              <CreatorsFilterList topicIsLoading={topicIsLoading} tagIsLoading={tagIsLoading} categoryIsLoading={categoryIsLoading} videoCategoryIsLoading={videoCategoryIsLoading}/>
              <PerformanceFilterList />
              {/* <AudienceFilterList /> */}
            </Row>
            <div className="filter-footer-wrap">
              <Btn className="btn-theme" disabled={isLoading || isDisabled}>
                Get Results 
              </Btn>
              <Btn className="p-0 border-0 text-decoration-underline" onClick={removeAll} disabled={isLoading || isDisabled}>
                Remove all filters 
              </Btn>
            </div>
          </Form>
        </OffcanvasBody>
      </Offcanvas>
    </Fragment>
  );
};

export default FilterOffcanvas;
