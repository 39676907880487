import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextField } from "@mui/material";
import { Col, Row, Form, FormGroup } from "reactstrap";
import * as yup from "yup";
import { Btn, Image } from "../../../AbstractElement";
import { dynamicImage } from "../../../Helper";
import getStorage, { KEYS } from "../../../Helper/getStorage";
import { useAuthContext } from "../../../Context/Auth/AuthContext";
import { useGetApi } from "../../../Api/useGetApi";
import { API } from "../../../Api/ApiList";
import { useEffect } from "react";

export const ProfileContainer = () => {
  const { getItem } = getStorage();
  const { user } = JSON.parse(getItem(KEYS.UAT)) || {};

  // const { data: user, refetch: userProfileRefetch, isLoading: creatorProfileLoading } = useGetApi({ url: `${API.user.getProfileDetails}`, options: { enabled: false, refetchOnWindowFocus: false }, });

  // console.log("user===>", user);

  // useEffect(() =>{
  //   if(creatorProfileLoading){
  //     userProfileRefetch()
  //   }
  // },[creatorProfileLoading,userProfileRefetch])
  
  // Form validation schema
  const schema = yup.object({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email address").required("Email is required"),
  });

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: user?.name || "",
      email: user?.email || "",
    },
  });

  const { handleUpdateProfile } = useAuthContext();
  const onSubmit = (data) => {
    handleUpdateProfile(data)
  };

  

  return (
    <Fragment>
      <div className="change-password">
        <div className="card">
          <div className="card-header text-start">
            <h4 className="mb-0">Update Your Profile</h4>
          </div>
          <div className="card-body">
            <div className="profile-page">
              <div className="profile-box">
                <div className="profile-wrap">
                  <Image src={dynamicImage("user/2.jpg")} alt="User" />
                  <div className="profile-detail">
                    <h5>{user?.name}</h5>
                    <h6 className="text-capitalize">{user?.account_type}</h6>
                  </div>
                </div>
              </div>
              <div className="general-details-wrap width-space w-100">
                <h5 className="inner-title">General Details</h5>
                <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                  <Row>
                    <Col lg={6}>
                      <FormGroup className="position-relative pb-4">
                        <label htmlFor="name" className="form-label">
                          Name <span className="text-danger">*</span>
                        </label>
                        <TextField
                          fullWidth
                          id="name"
                          {...register("name")}
                          error={!!errors.name}
                          helperText={errors.name?.message}
                          placeholder="Enter your name"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup className="position-relative pb-4">
                        <label htmlFor="email" className="form-label">
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <TextField
                          fullWidth
                          id="email"
                          type="email"
                          {...register("email")}
                          error={!!errors.email}
                          helperText={errors.email?.message}
                          placeholder="Enter your email"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <div className="d-flex justify-content-end">
                        <Btn block className="btn-theme d-inline-block w-auto" type="submit">
                          Update Profile
                        </Btn>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
