/* eslint-disable react-hooks/exhaustive-deps */
// import axios from "axios";
// import toast from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
// import React, { useEffect, useState } from "react";
import React, { useEffect } from "react";

// import Loader from "../../Layout/Loader";
// import { ROUTES } from "../../Routes/Routes";
import HomeContainer from "../../Component/Home";
import { useCreatorContext } from "../../Context/Creator/CreatorContext";
// import { instagramCallbackApi } from "../../Api";
// import { useAuthContext } from "../../Context/Auth/AuthContext";
// import getStorage from "../../Hooks/getStorage";

const HomePage = () => {
  // const { setItem, getItem, clearItems } = getStorage()
  // const navigate = useNavigate();
  // const [code, setCode] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const { setUatAvailable } = useAuthContext();

  const { removeUrlParams } = useCreatorContext();

  useEffect(() => {
    removeUrlParams()
  },[])
  
  // useEffect(() => {
  //   const url = window.location.href;
  //   const urlParams = new URLSearchParams(url.split("?")[1]);
  //   const codeParam = urlParams.get("code");
  //   setCode(codeParam);
    
  //   const handleInstagramLogin = async () => {
  //     if (code !== undefined && code !== null) {
  //       setLoading(true);
  //       try {
  //         const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${instagramCallbackApi}?code=${code}`);
  //         if (response.status === 201 || response.status === 200) {
  //           const {token, user} = response?.data?.data;
  //           const { name } = user
  //           localStorage.setItem("uat", JSON.stringify(token));
  //           localStorage.setItem("uName", JSON.stringify(name))

  //           const validToken = localStorage.getItem("uat");
  //           if (validToken) {
  //             setUatAvailable(true)
  //             toast.success(response.data.message);
  //             navigate(ROUTES.Home);
  //             setLoading(false);
  //           }
  //         }
  //       } catch (error) {
  //         toast.error(error.response.data.error);
  //         setLoading(false)
  //       }
  //     }
  //   };
  //   handleInstagramLogin();
  // }, [code, navigate]);

  // if (loading) {
  //   return <Loader />;
  // }

  return <HomeContainer />;
};

export default HomePage;
