import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import { dynamicImage } from "../../../Helper";
import { ROUTES } from "../../../Routes/Routes";
import { Btn, Image } from "../../../AbstractElement";
import getStorage, { KEYS } from "../../../Helper/getStorage";
import { useAuthContext } from "../../../Context/Auth/AuthContext";
import useApiState from "../../../Hooks/useApiState";
import ReCAPTCHA from "react-google-recaptcha";

const LoginForm = () => {
  const { setItem } = getStorage()
  const [show, setShow] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const toggleShow = () => setShow(!show);
  const { LoginSchema, handleLogin } = useAuthContext();
  const { register, handleSubmit, formState: { errors }, setError} = useForm({ resolver: yupResolver(LoginSchema)  });
  const navigate = useNavigate();
  const { loading, setLoading } = useApiState()

  const handleForgetPassword = () => {
    setItem(KEYS.FORGET, JSON.stringify((true)))
    navigate(ROUTES.Auth.ForgetPassword)
  }

  const onSubmit = (data) =>{ 
    if (!recaptchaToken) {
      setError("recaptcha", { message: "Please complete the reCAPTCHA verification." });
      return;
    }
    
    handleLogin({ data :{...data, recaptchaToken}, navigate, setLoading })};

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <Form className="auth-login_form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormGroup className="position-relative">
        <Image src={dynamicImage("svg/sms.svg")} className="form-icon" />
        <input {...register("email")} className={`form-control ${errors.email ? "invalid-field" : ""}`} placeholder="Enter Email Address" type="email" />
        {errors.email && (<span className="required-note">{errors.email.message}</span>)}
      </FormGroup>
      <FormGroup className="position-relative">
        <Image src={dynamicImage("svg/lock.svg")} className="form-icon" />
        <input {...register("password")} className={`form-control pr-45 ${errors.password ? "invalid-field" : ""}`} placeholder="Password" type={show ? "text" : "password"} />
        {errors.password && (<span className="required-note">{errors.password.message}</span>)}
        <div className="password-wrap" onClick={toggleShow}>
          <Image src={dynamicImage(`svg/${show ? "eye" : "eye-slash"}.svg`)} />
        </div>
      </FormGroup>

      <FormGroup className="position-relative">
        <ReCAPTCHA
          sitekey="6Lc_K4MqAAAAABt8ocIBvibDO1Prkbq17-hWASZm"
          onChange={handleRecaptchaChange}
        />
        {errors.recaptcha && (<span className="required-note">{errors.recaptcha.message}</span>)}
      </FormGroup>
      <div className="forgot-pass"><span onClick={handleForgetPassword}>Forgot Password?</span></div>
      <Btn block className="btn-theme" type="submit" disabled={loading} loading={loading}>Sign in</Btn>
    </Form>
  );
};

export default LoginForm;