import React, { useState } from "react";

const useShowMore = (text, wordLimit = 100) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggle = () => setIsExpanded((prev) => !prev);

    const displayText = isExpanded
        ? text
        : text?.split(" ").slice(0, wordLimit).join(" ") + (text?.split(" ")?.length > wordLimit ? "..." : "");

    return { displayText, isExpanded, toggle };
};

const About = ({ introduction }) => {
    const { displayText, isExpanded, toggle } = useShowMore(introduction, 25);

    return (
        <li className="custom-subtitle pt-0">
            <h5 className="mb-2">About Me</h5>
            <p>{displayText}</p>
            {introduction?.split(" ").length > 25 && (
                <button
                    className="btn btn-link p-0"
                    onClick={toggle}
                >
                    {isExpanded ? "less" : "more"}
                </button>
            )}
        </li>
    );
};

export default About;