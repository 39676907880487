import { ArrowOutward, Instagram, YouTube } from "@mui/icons-material";
import { Badge } from "@mui/material";
import React, { useCallback, useMemo } from "react";

import { Image } from "../../../../AbstractElement";
import { useCreatorFavoriteContext } from "../../../../Context/Creator/CreatorFavoriteContext";
import { dynamicImage, formatInstagramNumber, formatPercentage } from "../../../../Helper";
import { createSortHeader, TableCommon } from "../../../Common/Table";
import { useCreatorContext } from "../../../../Context/Creator/CreatorContext";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../Routes/Routes";

export const CreatorTable = ({ isLoading, sortColumn, sortOrder, handleSort, limit, handleLimitChange, totalPages, page, setPage, setSortOrder, total, params }) => {
  const { creatorData, isAllFilterChecked, handleTableCheckboxChange, handleSelectAllRow, selectedFilterRows } = useCreatorFavoriteContext();
  
  const { handleUrl, platFormSelectedItem } = useCreatorContext();

  const followerHead = platFormSelectedItem?.title === "instagram";

  const navigate = useNavigate();

  // Function to handle select all checkboxes
  const handleSelectAll = (e) => handleSelectAllRow({e,tableId:"filters"});
  const handleCheckboxChange = useCallback(
    (id) => {handleTableCheckboxChange({ id, tableId:"filters" });},
    [handleTableCheckboxChange]
  );


  // Table Headers
  const creatorFilterTableHead = [
    { class: "tab-check", render: () => <input type="checkbox" className="check-box" checked={isAllFilterChecked} onChange={handleSelectAll} /> }, 
    createSortHeader("text-start", "Creator",true, "id", sortColumn, sortOrder, handleSort), 
    createSortHeader("", followerHead ? "Followers" : "Subscribers" ,true, "follower_count", sortColumn, sortOrder, handleSort), 
    createSortHeader("", "Engagement rate",true, "avg_engagement", sortColumn, sortOrder, handleSort), 
    // followerHead  && createSortHeader("", "Is verified",true, "is_verified", sortColumn, sortOrder, handleSort), 
    createSortHeader("", "Avg views",true, "avg_view_count", sortColumn, sortOrder, handleSort, "Filter creators by average views on top posts."),
    createSortHeader("", "Avg likes",true, "avg_like_count", sortColumn, sortOrder, handleSort, "Filter creators by average likes on top posts."),
    createSortHeader("", "Avg comments",true, "avg_comment_count", sortColumn, sortOrder, handleSort, "Filter creators by average comments on top posts."),
  ].filter(Boolean); // Filter out false or undefined entries

  // Helper to create rows
  const creatorFilterTableRow = useMemo(() => {
    const createRow = (item, selectedFilterRows, handleCheckboxChange) => [
      { class: "", render: () => (<input type="checkbox" className="check-box" checked={selectedFilterRows.some((selected) => selected.id === item.id)} onChange={() => handleCheckboxChange(item.id)}/>),},
      { class: "",
        render: () => (
          <div className="creator-wrap">
            <div className="user-img" onClick={() => handleCheckboxChange(item.id)}>
              <Image className="img-fluid" src={item.image_url || dynamicImage("svg/dummy-user.svg")} alt="User" onError={(e) => {e.target.src = dynamicImage("svg/dummy-user.svg");}}/>
            </div>
            <div className="creator-detail">
              <div className="name mb-1" onClick={()=> navigate(ROUTES.Pages.CreatorProfiles.replace(':id', item?.id))}>
                <h5 className="m-0" >{item.full_name ? item.full_name : "Unknown"}</h5>
                {item.is_verified && (<Image className="img-fluid" src={dynamicImage("svg/verify.svg")} />)}
                {item.tag && <Badge className="ai-badge" badgeContent={`${item.tag} recommended`} />}
              </div>
              <h6>
                {item.platform === "instagram" ? <Instagram fontSize="small" /> : <YouTube fontSize="small" />}
                <span onClick={() => handleUrl(item)}>@{item.platform_username}</span>
                <ArrowOutward fontSize="10px" />
              </h6>
            </div>
          </div>
        ),
      },
      { class: "", render: () => item.follower_count !== null ? formatInstagramNumber(item.follower_count) : "NA" },
      { class: "", render: () => (item.avg_engagement !== -1 ? formatPercentage(item.avg_engagement) : "-") },
      // followerHead && { class: "text-capitalize", render: () => item.is_verified === true ? item.is_verified.toString() : item.is_verified === false ? item.is_verified.toString() : "-"},
      { class: "", render: () => item.avg_view_count !== null ? formatInstagramNumber(item.avg_view_count) : "-" },
      { class: "", render: () => item.avg_like_count !== null ? formatInstagramNumber(item.avg_like_count) : "-" },
      { class: "", render: () => item.avg_comment_count !== null ? formatInstagramNumber(item.avg_comment_count) : "-" },
      
    ].filter(Boolean); // Filter out false or undefined entries
  
    return creatorData.map((item) => { 
      return createRow(item, selectedFilterRows, handleCheckboxChange)});
  }, [creatorData, handleCheckboxChange, handleUrl, selectedFilterRows]);

  return <TableCommon isLoading={isLoading} mainClass="creators-table-wrap" tabClass="align-middle creators-table" head={creatorFilterTableHead} rows={creatorFilterTableRow} pagination={{ limit, handleLimitChange, totalPages, currentPage: page, setCurrentPage: setPage }} onCheckboxChange={handleCheckboxChange} tableCheck={{tableId:"filters",selectedRows:selectedFilterRows, handleSort, setSortOrder, total, params}}/>;
};
